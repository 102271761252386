import { incomeColor, expenseColor } from '../themeVariables';

const colorBoxStyle = {
  display: 'inline-block',
  height: '10px',
  width: '14px',
  marginRight: '5px',
};

export const incomeBoxStyle = {
  ...colorBoxStyle,
  background: incomeColor,
};

export const expenseBoxStyle = {
  ...colorBoxStyle,
  background: expenseColor,
};
