import React, { useEffect } from 'react';
import { SnackbarProvider } from 'notistack';
import { Provider, useDispatch } from 'react-redux';
import { ThemeProvider } from '@mui/material';

import Router from 'app/routes/index';
import store from 'store/index';
import useAuthInfo from 'customHooks/useAuthInfo';
import { changeTabFavIcon } from 'utilities/helpers';
import { useLoadUserQuery } from 'services/private/auth';
import { userLoaded } from 'store/slices/authSlice';
import useScrollToTop from 'customHooks/useScrollToTop';
import theme from 'styles/mui/theme';

function App() {
  const { data: loadUserData, isLoading } = useLoadUserQuery();
  const { companyDetail } = useAuthInfo();
  const dispatch = useDispatch();
  useScrollToTop();

  useEffect(() => {
    if (loadUserData) {
      dispatch(userLoaded(loadUserData));
    }
  }, [loadUserData]);

  useEffect(() => {
    if (companyDetail) {
      changeTabFavIcon(companyDetail);
    }
  }, [companyDetail]);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <SnackbarProvider
          preventDuplicate
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {!isLoading && <Router />}
        </SnackbarProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
