/* eslint-disable no-nested-ternary */
import React from 'react';
import { List } from '@mui/material';
import useAuthInfo from 'customHooks/useAuthInfo';
import useGetSidebarLinks from 'containers/common/customHooks/useGetSidebarLinks';
import { useSelector } from 'react-redux';
import SidebarItem from './SidebarItem';
import SidebarNested from './SidebarNested';
import AccountantSidebar from './AccountantSidebar';

function SidebarContent() {
  const {
    allowSystemAccess,
    isAccountingDept,
  } = useAuthInfo();
  const { superUserSidebar, newUserSidebar } = useGetSidebarLinks();
  const { collapse } = useSelector(state => state.sidebar);
  return (
    <List className="sidebar" style={{ width: collapse ? '64px' : '250px' }}>
      {allowSystemAccess ? (
        isAccountingDept ? (
          <AccountantSidebar />
        ) : (
          <List>
            {
              superUserSidebar.map(link => {
                if (link?.data) {
                  return (
                    <SidebarNested title={link.title} icon={link.icon} data={link.data} />
                  );
                }
                return (
                  <SidebarItem
                    title={link.title}
                    icon={link.icon}
                    path={link.path}
                  />
                );
              })
            }
          </List>
        )
      ) : (
        <List>
          {
            newUserSidebar.map(link => {
              if (link?.data) {
                return (
                  <SidebarNested title={link.title} icon={link.icon} data={link.data} />
                );
              }
              return (
                <SidebarItem
                  title={link.title}
                  path={link.path}
                  icon={link.icon}
                />
              );
            })
          }
        </List>
      )}
    </List>
  );
}

export default SidebarContent;
