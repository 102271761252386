const sidebarStylings = {
  marginTop: '4rem',
};
export const sidebarExpand = {
  marginTop: sidebarStylings.marginTop,
  width: '250px',
};

export const sidebarCollapse = {
  marginTop: sidebarStylings.marginTop,
  width: '65px',
  overflowX: 'hidden',
};
