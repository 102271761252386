import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import GlobalLoader from 'containers/common/loaders/GlobalLoader';
import Dashboard from 'containers/pages/dashboard';
import MainWrapper from 'containers/common/layout/MainWrapper';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';

const LogIn = lazy(() => import('containers/pages/auth/LogIn/index'));
const ForgotPassword = lazy(() => import('containers/pages/auth/ForgotPassword/index'));
const ChangePassword = lazy(() => import('containers/pages/auth/ChangePassword/index'));
const ExpensesByCategoryDetails = lazy(() => import('containers/pages/Reports/ReportTypes/ExpensesByCategoryDetails'));

// Items

const ItemsMainPage = lazy(() => import('containers/pages/accounting/Items/Main/index'));
const ItemsDetailPage = lazy(() => import('containers/pages/accounting/Items/Detail/index'));
// const ItemsEditPage = lazy(() => import('containers/pages/accounting/Items/Edit/index'));
const ItemsFormPage = lazy(() => import('containers/pages/accounting/Items/forms/index'));

// Banking

const BankingPage = lazy(() => import('containers/pages/accounting/Banking/index'));
const BankTransactionMain = lazy(() => import('containers/pages/accounting/Banking/BankTransactions/Detail'));
const ImportBankTransactionStatements = lazy(() => import('containers/pages/accounting/Banking/BankTransactions/Add'));
const BankFormPage = lazy(() => import('containers/pages/accounting/Banking/forms/index'));

// sales

const SalesAccountMainPage = lazy(() => import('containers/pages/accounting/sales/Accounts/Main/index'));
const SalesAccountAddPage = lazy(() => import('containers/pages/accounting/sales/Accounts/Add/index'));
const SalesAccountDetailPage = lazy(() => import('containers/pages/accounting/sales/Accounts/Detail/index'));
const SalesAccountContactsEditPage = lazy(() => import('containers/pages/accounting/sales/Accounts/Contacts/Edit/index'));
// const SalesAccountEditPage = lazy(() => import('containers/pages/accounting/sales/Accounts/Edit/index'));
const SalesAccountContactsAddPage = lazy(() => import('containers/pages/accounting/sales/Accounts/Contacts/Add/index'));
const SalesAccountEmailPage = lazy(() => import('containers/pages/accounting/sales/Accounts/Email/index'));

// Reports

// const ApAgingDetails = lazy(() => import('containers/pages/Reports/ReportTypes/ApAgingDetails'));

// Customers
const CustomerMainPage = lazy(() => import('containers/pages/accounting/sales/Customers/Main/index'));
const CustomerDetailPage = lazy(() => import('containers/pages/accounting/sales/Customers/Detail'));
const CustomerAddPage = lazy(() => import('containers/pages/accounting/sales/Customers/Add'));
const CustomerEditPage = lazy(() => import('containers/pages/accounting/sales/Customers/Edit'));

// Quotations
const QuotationsMainPage = lazy(() => import('containers/pages/accounting/sales/Quotations/Main/index'));
const QuotationsAddPage = lazy(() => import('containers/pages/accounting/sales/Quotations/Add/index'));
const QuotationsDetailPage = lazy(() => import('containers/pages/accounting/sales/Quotations/Detail/index'));
const QuotationsEditPage = lazy(() => import('containers/pages/accounting/sales/Quotations/Edit/index'));

// ProInvoice
const ProInvoicesMainPage = lazy(() => import('containers/pages/accounting/sales/ProInvoices/Main/index'));
const ProInvoicesDetailPage = lazy(() => import('containers/pages/accounting/sales/ProInvoices/Detail/index'));
const ProInvoicesAddPage = lazy(() => import('containers/pages/accounting/sales/ProInvoices/Add/index'));
const ProInvoicesEditPage = lazy(() => import('containers/pages/accounting/sales/ProInvoices/Edit/index'));

// Invoices
const AddInvoicePage = lazy(() => import('containers/pages/accounting/sales/Invoices/Add/index'));
const InvoicesMainPage = lazy(() => import('containers/pages/accounting/sales/Invoices/Main/index'));
const InvoicesDetailPage = lazy(() => import('containers/pages/accounting/sales/Invoices/Detail/index'));
const InvoicesAddPage = lazy(() => import('containers/pages/accounting/sales/Invoices/Add/index'));
const InvoicesEditPage = lazy(() => import('containers/pages/accounting/sales/Invoices/Edit/index'));

// Payments Recieved

const PaymentsReceivedMainPage = lazy(() => import('containers/pages/accounting/sales/PaymentsReceived/Main/index'));
const PaymentsReceivedDetailPage = lazy(() => import('containers/pages/accounting/sales/PaymentsReceived/Detail/index'));
const PaymentsReceivedAddPage = lazy(() => import('containers/pages/accounting/sales/PaymentsReceived/Add/index'));
const PaymentsReceivedEditPage = lazy(() => import('containers/pages/accounting/sales/PaymentsReceived/Edit/index'));

// Credit Notes

const CreditNotesAddPage = lazy(() => import('containers/pages/accounting/sales/CreditNotes/Add/index'));
const CreditNotesMainPage = lazy(() => import('containers/pages/accounting/sales/CreditNotes/Main/index'));

// Supplier

const SuppliersMainPage = lazy(() => import('containers/pages/accounting/purchases/Suppliers/Main/index'));
const SuppliersAddPage = lazy(() => import('containers/pages/accounting/purchases/Suppliers/Add/index'));
const SuppliersEditPage = lazy(() => import('containers/pages/accounting/purchases/Suppliers/Edit/index'));
const SuppliersDetailPage = lazy(() => import('containers/pages/accounting/purchases/Suppliers/Detail/index'));
const SupplierEmailPage = lazy(() => import('containers/pages/accounting/purchases/Suppliers/Email/index'));
const SuppliersContactsAddPage = lazy(() => import('containers/pages/accounting/purchases/Suppliers/Contacts/Add/index'));
const SuppliersContactsEditPage = lazy(() => import('containers/pages/accounting/purchases/Suppliers/Contacts/Edit/index'));

// Expenses

const ExpensesMainPage = lazy(() => import('containers/pages/accounting/purchases/Expenses/Main/index'));
const ExpensesAddPage = lazy(() => import('containers/pages/accounting/purchases/Expenses/Add/index'));
const ExpensesEditPage = lazy(() => import('containers/pages/accounting/purchases/Expenses/Edit/index'));
const ExpensesDetailPage = lazy(() => import('containers/pages/accounting/purchases/Expenses/Detail/index'));

// Purchase Orders

const PurOrdersDetailPage = lazy(() => import('containers/pages/accounting/purchases/PurOrders/Detail/index'));
const PurOrdersMainPage = lazy(() => import('containers/pages/accounting/purchases/PurOrders/Main/index'));
const PurOrderFormPage = lazy(() => import('containers/pages/accounting/purchases/PurOrders/forms/index'));

// Bills
const BillsMainPage = lazy(() => import('containers/pages/accounting/purchases/Bills/Main/index'));
const BillsAddPage = lazy(() => import('containers/pages/accounting/purchases/Bills/Add/index'));

// Payments Made

const PaymentsMadeMainPage = lazy(() => import('containers/pages/accounting/purchases/PaymentsMade/Main/index'));

// Suppliers Credits

const SupplierCreditsMainPage = lazy(() => import('containers/pages/accounting/purchases/SupplierCredits/Main/index'));

// Booking
const Booking = lazy(() => import('containers/pages/booking/hotels'));
const HotelDetail = lazy(() => import('containers/pages/booking/hotelDetails'));
const RoomDetails = lazy(() => import('containers/pages/booking/roomDetails'));
const HotelFilters = lazy(() => import('containers/pages/booking/hotelFilters'));
const BookingQueues = lazy(() => import('containers/pages/booking/bookingQueues'));
const BookingConfirmation = lazy(() => import('containers/pages/booking/bookingConfirmation'));
const BookingDetails = lazy(() => import('containers/pages/booking/bookingDetails'));
const BookingUsers = lazy(() => import('containers/pages/booking/users'));
const BookingUserDetails = lazy(() => import('containers/pages/booking/bookingUserDetails'));

function Router() {
  return (
    <BrowserRouter>
      <Suspense fallback={<GlobalLoader isLoading />}>
        <Routes>
          <Route path="/" element={<MainWrapper />}>
            <Route path="/auth" element={<PublicRoutes />}>
              <Route path="sign-in" element={<LogIn />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password" element={<ChangePassword />} />
            </Route>

            <Route path="/" element={<PrivateRoutes />}>
              <Route index element={<Dashboard />} />
              <Route path="/pages" element={<Outlet />}>
                <Route path="accounting" element={<Outlet />}>
                  <Route path="items" element={<Outlet />}>
                    <Route path="add" element={<ItemsFormPage />} />
                    <Route path=":id/edit" element={<ItemsFormPage />} />
                    <Route path=":id/detail" element={<ItemsDetailPage />} />
                    <Route path="" element={<ItemsMainPage />} />
                  </Route>

                  <Route path="banking" element={<Outlet />}>
                    <Route path=":id/edit" element={<BankFormPage />} />
                    <Route path="add" element={<BankFormPage />} />
                    <Route path="import" element={<ImportBankTransactionStatements />} />
                    <Route path="transaction" element={<BankTransactionMain />} />
                    <Route path="" element={<BankingPage />} />
                  </Route>

                  <Route path="sales" element={<Outlet />}>
                    <Route path="accounts" element={<Outlet />}>
                      <Route
                        path="contacts/:id/edit"
                        element={<SalesAccountContactsEditPage />}
                      />
                      <Route path=":id/email" element={<SalesAccountEmailPage />} />
                      <Route path=":id/detail" element={<SalesAccountDetailPage />} />
                      <Route path=":id/edit" element={<SalesAccountAddPage />} />
                      <Route
                        path=":id/addContact"
                        element={<SalesAccountContactsAddPage />}
                      />
                      <Route path="add" element={<SalesAccountAddPage />} />

                      <Route path="" element={<SalesAccountMainPage />} />
                    </Route>

                    <Route path="customers" element={<Outlet />}>
                      <Route path=":id/edit" element={<CustomerEditPage />} />
                      <Route path=":id/detail" element={<CustomerDetailPage />} />
                      <Route path="add" element={<CustomerAddPage />} />
                      <Route path="" element={<CustomerMainPage />} />
                    </Route>

                    {/* Quotations */}
                    <Route path="quotations" element={<Outlet />}>
                      <Route
                        path=":quotationId/createInvoice"
                        element={<AddInvoicePage />}
                      />

                      <Route
                        path=":id/createProInvoice"
                        element={<ProInvoicesAddPage />}
                      />

                      <Route path=":id/edit" element={<QuotationsEditPage />} />

                      <Route path=":id/detail" element={<QuotationsDetailPage />} />

                      <Route path="add" element={<QuotationsAddPage />} />

                      <Route path="" element={<QuotationsMainPage />} />
                    </Route>

                    {/* ProInvoice */}
                    <Route path="proInvoices" element={<Outlet />}>
                      <Route
                        path=":proInvoiceId/createInvoice"
                        element={<AddInvoicePage />}
                      />

                      <Route path=":id/edit" element={<ProInvoicesEditPage />} />

                      <Route path="add" element={<ProInvoicesAddPage />} />

                      <Route path=":id/detail" element={<ProInvoicesDetailPage />} />

                      <Route path="" element={<ProInvoicesMainPage />} />
                    </Route>

                    {/* Invoices */}
                    <Route path="invoices" element={<Outlet />}>
                      <Route
                        path=":invoiceId/createCreditNotes"
                        element={<CreditNotesAddPage />}
                      />
                      <Route path=":id/detail" element={<InvoicesDetailPage />} />
                      <Route path=":id/edit" element={<InvoicesEditPage />} />
                      <Route path="add" element={<InvoicesAddPage />} />
                      <Route path="" element={<InvoicesMainPage />} />
                    </Route>

                    {/* Payments Recieved */}
                    <Route path="paymentsReceiveds" element={<Outlet />}>
                      <Route path="add" element={<PaymentsReceivedAddPage />} />
                      <Route path=":id/edit" element={<PaymentsReceivedEditPage />} />
                      <Route path=":id/detail" element={<PaymentsReceivedDetailPage />} />
                      <Route path="" element={<PaymentsReceivedMainPage />} />
                    </Route>

                    {/* creditNotes */}
                    <Route path="creditNotes" element={<Outlet />}>
                      <Route path="add" element={<CreditNotesAddPage />} />
                      <Route path="" element={<CreditNotesMainPage />} />
                    </Route>
                  </Route>
                  {/* purchases Suppliers */}
                  <Route path="purchases" element={<Outlet />}>
                    <Route path="suppliers" element={<Outlet />}>
                      <Route
                        path="contacts/:id/edit"
                        element={<SuppliersContactsEditPage />}
                      />
                      <Route
                        path=":id/addContact"
                        element={<SuppliersContactsAddPage />}
                      />
                      <Route path=":id/email" element={<SupplierEmailPage />} />
                      <Route path=":id/detail" element={<SuppliersDetailPage />} />
                      <Route path=":id/edit" element={<SuppliersEditPage />} />
                      <Route path="add" element={<SuppliersAddPage />} />
                      <Route path="" element={<SuppliersMainPage />} />
                    </Route>

                    <Route path="expenses" element={<Outlet />}>
                      <Route path=":id/detail" element={<ExpensesDetailPage />} />
                      <Route path=":id/edit" element={<ExpensesEditPage />} />
                      <Route path="add" element={<ExpensesAddPage />} />
                      <Route path="" element={<ExpensesMainPage />} />
                    </Route>

                    <Route path="purOrders" element={<Outlet />}>
                      <Route path=":purOrderId/createBill" element={<BillsAddPage />} />
                      <Route path=":id/detail" element={<PurOrdersDetailPage />} />
                      <Route path=":id/edit" element={<PurOrderFormPage />} />
                      <Route path="add" element={<PurOrderFormPage />} />
                      <Route path="" element={<PurOrdersMainPage />} />
                    </Route>

                    <Route path="bills" element={<Outlet />}>
                      <Route path="add" element={<BillsAddPage />} />
                      <Route path="" element={<BillsMainPage />} />
                    </Route>

                    <Route path="paymentsMade" element={<Outlet />}>
                      <Route path="" element={<PaymentsMadeMainPage />} />
                    </Route>

                    <Route path="supplierCredits" element={<Outlet />}>
                      <Route path="" element={<SupplierCreditsMainPage />} />
                    </Route>
                  </Route>
                </Route>

                <Route path="reports" element={<Outlet />}>
                  <Route
                    path="expensesByCategory/details"
                    element={<ExpensesByCategoryDetails />}
                  />
                </Route>
              </Route>

              <Route path="booking" element={<Outlet />}>
                <Route index element={<HotelFilters />} />

                <Route path="hotels" element={<Outlet />}>
                  <Route index element={<Booking />} />
                  <Route path=":hotelCode/:hotelName" element={<HotelDetail />} />
                  <Route path=":hotelCode/:hotelName/:roomId" element={<RoomDetails />} />
                </Route>

                <Route path="users" element={<Outlet />}>
                  <Route index element={<BookingUsers />} />
                  <Route path=":id" element={<BookingUserDetails />} />
                </Route>
                <Route path="confirmation" element={<BookingConfirmation />} />

                <Route path="queues" element={<Outlet />}>
                  <Route index element={<BookingQueues />} />
                  <Route path=":id" element={<BookingDetails />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default Router;
