import { createTheme } from '@mui/material';
import { incomeColor } from './themeVariables';

export const palette = {
  primary: {
    main: '#0097B2',
    contrastText: '#fff',
  },

  primaryMuted: {
    main: '#000',
    contrastText: '#ffffff',
  },
  greyColor: {
    // main: '#70bbfd',
    contrastText: '#808080',
  },
};

const theme = createTheme({
  palette,
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
    },
  },

  typography: {
    pageTitle: {
      fontSize: '1.3rem',
      fontWeight: 300,
      fontFamily: 'Roboto, sans-serif',
      marginBottom: '10px',

      '@media (max-width: 991px)': {
        fontSize: '1.2rem',
      },
    },
    successText: {
      fontSize: '13px',
      fontWeight: 400,
      fontFamily: 'Roboto, sans-serif',
      color: incomeColor,

      '@media (max-width: 991px)': {
        fontSize: '11px',
      },
    },
    h4: {
      fontSize: '18px !important',
      lineHeight: '24px',
    },
    h3: {
      fontSize: '32px',
      lineHeight: '30px',
    },
    h5: {
      fontSize: '14px !important',
    },
    h2: {
      fontSize: '30px',
      lineHeight: '36px',
    },
  },

  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          pageTitle: 'h1',
        },
      },
    },

    MuiContainer: {
      defaultProps: {
        maxWidth: 'xxl',
      },

      styleOverrides: {
        root: {
          paddingLeft: '12px !important',
          paddingRight: '12px !important',
          paddingTop: '12px !important',
        },
      },
    },

    MuiCheckbox: {
      variants: [
        {
          props: { variant: 'mutedColor' },
          style: {
            '& svg': {
              fill: '#dddddd',
            },

            '&.Mui-checked': {
              '& svg': {
                fill: palette.primary.main,
              },
            },
          },
        },
      ],
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '10px',
          color: '#c90000',
        },
      },
    },

    MuiButtonBase: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          textTransform: 'capitalize !important',
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(0,0,0,0.5)',
        },
      },
    },

    MuiInputBase: {
      defaultProps: {
        size: 'small',
      },
    },
  },
});

export default theme;
