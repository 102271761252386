export { default as GroupsIcon } from '@mui/icons-material/Groups';
export { default as CategoryIcon } from '@mui/icons-material/Category';
export { default as AccountBalanceIcon } from '@mui/icons-material/AccountBalance';
export { default as MonetizationOnIcon } from '@mui/icons-material/MonetizationOn';
export { default as ShoppingCartIcon } from '@mui/icons-material/ShoppingCart';
export { default as PersonIcon } from '@mui/icons-material/Person';
export { default as AssessmentIcon } from '@mui/icons-material/Assessment';
export { default as MenuBookIcon } from '@mui/icons-material/MenuBook';
export { default as SavingsIcon } from '@mui/icons-material/Savings';
export { default as EnergySavingsLeafIcon } from '@mui/icons-material/EnergySavingsLeaf';
export { default as DashboardIcon } from '@mui/icons-material/Dashboard';
export { default as StorageIcon } from '@mui/icons-material/Storage';
export { default as DeleteIcon } from '@mui/icons-material/Delete';
export { default as ExpandLessIcon } from '@mui/icons-material/ExpandLess';
export { default as ExpandMoreIcon } from '@mui/icons-material/ExpandMore';
export { default as ArrowBackIcon } from '@mui/icons-material/ArrowBack';
export { default as LayersIcon } from '@mui/icons-material/Layers';
export { default as AdminPanelSettingsIcon } from '@mui/icons-material/AdminPanelSettings';
export { default as LocalActivityIcon } from '@mui/icons-material/LocalActivity';
export { default as Diversity2Icon } from '@mui/icons-material/Diversity2';
export { default as SettingsIcon } from '@mui/icons-material/Settings';
export { default as AccountCircleIcon } from '@mui/icons-material/AccountCircle';
export { default as MenuIcon } from '@mui/icons-material/Menu';
