/**
 *
 * @param {string} type Type of transaction like invoice, bill, etc.
 * @param {string | number} id ID of transaction
 * @param {boolean} isSale pass true if module is sale
 * @returns {string} Link of detail page of transaction
 */
function getLinkAddress(type, id, isSale = false) {
  let link = '';

  if (type === 'Invoice') link = `/pages/accounting/sales/invoices/${id}/detail`;
  else if (
    type === 'Invoice Payment' ||
    type === 'Payment Received' ||
    type === 'Customer Receipt' ||
    (type === 'Excess Payment' && isSale)
  ) link = `/pages/accounting/sales/paymentsReceived/${id}/detail`;
  else if (type === 'Credit Note') link = `/pages/accounting/sales/creditNotes/${id}/detail`;
  else if (type === 'Bill') link = `/pages/accounting/purchases/bills/${id}/detail`;
  else if (
    type === 'Bill Payment' ||
    type === 'Payment Made' ||
    type === 'Supplier Payment' ||
    (type === 'Excess Payment' && !isSale)
  ) link = `/pages/accounting/purchases/paymentsMade/${id}/detail`;
  else if (type === 'Debit Note') link = `/pages/accounting/purchases/supplierCredits/${id}/detail`;
  else if (type === 'Expense' || type === 'Expense Paid') link = `/pages/accounting/purchases/expenses/${id}/detail`;
  else if (type === 'Journal') link = `/pages/accounting/accountant/journals/${id}/detail`;
  else if (type === 'Account') link = `/pages/accounting/sales/accounts/${id}/detail`;
  else if (type === 'Customer') link = `/pages/accounting/sales/customers/${id}/detail`;
  else if (type === 'Quotation') link = `/pages/accounting/sales/quotations/${id}/detail`;
  else if (type === 'Pro Invoice') link = `/pages/accounting/sales/proInvoices/${id}/detail`;
  else if (type === 'Supplier') link = `/pages/accounting/purchases/suppliers/${id}/detail`;
  else if (type === 'Purchase Order') link = `/pages/accounting/purchases/purOrders/${id}/detail`;
  return link;
}

export default getLinkAddress;
