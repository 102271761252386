import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useSelector } from 'react-redux';
import MeetingForm from './MeetingForm';

function InternalMeetingActionsForm() {
  const [modal, setModal] = useState(false);
  const { user } = useSelector(state => state.auth);
  const isStaff =
    Boolean(user.groups.length) && user.groups[0].name.includes('staff');

  const toggle = () => setModal(!modal);

  return (
    <>
      <Modal
        className="  modal-dialog--custom"
        isOpen={modal}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>Schedule Meeting</ModalHeader>
        <ModalBody>
          <MeetingForm toggle={toggle} />
        </ModalBody>
      </Modal>

      {!isStaff && (
        <div className="d-flex justify-content-end">
          <button
            type="button"
            id="submitSearch"
            className="btn btn-primary btn-sm square mb-2"
            onClick={toggle}
          >
            Add Internal Meeting
          </button>
        </div>
      )}
    </>
  );
}

export default InternalMeetingActionsForm;
