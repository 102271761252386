/* eslint-disable object-shorthand */
import { privateAPI } from './index';

export const dashboardAPI = privateAPI.injectEndpoints({
  reducerPath: 'publicAPI',

  endpoints: build => ({
    getNewlyHiredEmployees: build.query({
      query: () => 'top/three/users',
    }),
    getLatestMeetings: build.query({
      query: () => 'top/three/meetings',
    }),
    getRecentLeaves: build.query({
      query: () => 'top/four/leaves',
    }),
    getTotalReceivableData: build.query({
      query: () => 'report/ar/aging/detail?duration=today&date_type=due_date',
    }),
    getTotalPayableData: build.query({
      query: () => 'report/ap/aging/details?duration=today&date_type=due_date',
    }),
    getIncomeExpenseData: build.query({
      query: duration => `income/expense/dashboard?duration=${duration}`,
    }),
    getTopExpenses: build.query({
      query: duration => `top/expenses/dashboard?duration=${duration}`,
    }),
  }),
});

export const {
  useGetNewlyHiredEmployeesQuery,
  useGetLatestMeetingsQuery,
  useGetRecentLeavesQuery,
  useGetTotalReceivableDataQuery,
  useGetTotalPayableDataQuery,
  useGetIncomeExpenseDataQuery,
  useGetTopExpensesQuery,
} = dashboardAPI;
