/* eslint-disable no-nested-ternary */
import moment from 'moment';
import axios from 'axios';
import startCase from 'lodash/startCase';
import { lowerCase } from 'lodash';

const INJECTED_SPINNER_ID = 'injected-spinner';

export const formatReportComparisonFilters = filters => {
  const selectedNumberOfPeriods = +filters.number_of_periods || 0;

  if (filters.comparison === 'yearly') {
    const currentYear = moment().year();

    return {
      comparison: filters.comparison,
      number_of_year: filters.number_of_periods,
      comparison_start_date: `${currentYear}-01-01`,
      comparison_end_date: `${currentYear}-12-31`,
    };
  }
  if (filters.comparison === 'monthly') {
    return {
      comparison: filters.comparison,
      comparison_start_date: moment()
        .subtract(selectedNumberOfPeriods, 'months')
        .format('YYYY-MM-DD'),
      comparison_end_date: moment().format('YYYY-MM-DD'),
    };
  }
  return {};
};

export const calculateSupplierTransactionTotals = (headings = [], transactions = []) => {
  const totals = [];
  headings.forEach(({ key: comparisonKey }) => {
    const periodTotals = transactions.reduce(
      (acc, val) => ({
        quantity: acc.quantity + val[`${comparisonKey}_quantity`] || 0,
        amount: acc.amount + val[`${comparisonKey}_amount`] || 0,
      }),
      { quantity: 0, amount: 0 }
    );
    totals.push({
      [`${comparisonKey}_quantity`]: periodTotals.quantity,
      [`${comparisonKey}_amount`]: periodTotals.amount,
    });
  });
  return totals;
};

export const convertSelectedDurationToDates = (
  comparison = '',
  selectedDuration = ''
) => {
  if (comparison && selectedDuration) {
    if (comparison === 'yearly') {
      return {
        start_date: `${selectedDuration}-01-01`,
        end_date: `${selectedDuration}-12-31`,
      };
    }
    if (comparison === 'monthly') {
      const [month, year] = selectedDuration.split('-');
      const startDate = moment(`${year}-${moment().month(month).format('M')}-01`).format(
        'YYYY-MM-DD'
      );

      return {
        start_date: startDate,
        // Subtracting 1 day because we get first day of next month which we do not require
        // In other words exclusive of end date after adding 1 month into start date
        end_date: moment(startDate)
          .add(1, 'month')
          .subtract(1, 'day')
          .format('YYYY-MM-DD'),
      };
    }
  }
  return {
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
  };
};

export const fileUrlWithTimeStamp = (url = '') => `${url}?timestamp=${new Date().getTime()}`;

export const downloadFileWithURL = (fileURL, fileName = 'document') => {
  axios
    .get(fileUrlWithTimeStamp(fileURL), {
      responseType: 'blob',
    })
    .then(res => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(res.data);
      link.download = fileName;
      link.target = '_blank';
      link.click();
    })
    .catch(() => {
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = fileName;
      link.target = '_blank';
      link.click();
    });
};

export const downloadFileWithFileObject = (fileObject = {}) => {
  try {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(fileObject);
    link.download = fileObject.name || 'document';
    link.target = '_blank';
    link.click();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getItemsOptions = values => values
    .filter(
      (singleItem, index, items) => items.findIndex(item => item.service_type === singleItem.service_type) === index
    )
    .map(item => ({
      value: item.service_type,
      label: item.service_type,
    }));

export function extractContentFromHTML(htmlString) {
  const span = document.createElement('span');
  span.innerHTML = htmlString;
  return span.textContent || span.innerText;
}

export const injectSpinner = (selector, customSpinner) => {
  if (!selector) return;
  try {
    const target = document.querySelector(selector);
    target.style.position = 'relative';

    const spinnerNode = document.createElement('span');
    spinnerNode.id = INJECTED_SPINNER_ID;
    spinnerNode.style.position = 'absolute';
    spinnerNode.style.top = '50%';
    spinnerNode.style.left = '50%';
    spinnerNode.style.transform = 'translate(-50%,-50%)';
    spinnerNode.style.width = '100%';
    spinnerNode.style.height = '100%';
    spinnerNode.style.display = 'flex';
    spinnerNode.style.alignItems = 'center';
    spinnerNode.style.justifyContent = 'center';
    spinnerNode.style.backgroundColor = 'rgba(32, 32, 32, 0.650)';
    spinnerNode.style.zIndex = '99999';
    spinnerNode.innerHTML = customSpinner ? (
      // eslint-disable-next-line react/react-in-jsx-scope, react/no-danger, react/jsx-filename-extension
      <span dangerouslySetInnerHTML={{ __html: customSpinner }} />
    ) : (
      '<span class="h1 custom-text-primary fas fa-spinner fa-spin" />'
    );
    target.appendChild(spinnerNode);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error.message);
  }
};

export const removeSpinner = () => {
  try {
    const spinnerNode = document.getElementById(INJECTED_SPINNER_ID);
    if (spinnerNode) spinnerNode.remove();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error.message);
  }
};

export const transformSocketData = socketData => {
  if (socketData && socketData.status === 'success') {
    return {
      data: socketData.data || null,
      isSuccess: true,
      isError: false,
    };
  }
  return {
    data: socketData.data || null,
    isSuccess: false,
    isError: true,
    errorMessage: socketData.data || 'Something went wrong',
  };
};

export const convertURLToFile = async url => {
  const fileName = url.substring(url.lastIndexOf('/') + 1);
  const response = await fetch(`${url}?timestamp=${new Date().getTime()}`);
  const blob = await response.blob();
  const file = new File([blob], `${fileName}`, {
    type: blob.type,
  });
  return file;
};

export const getFlattenArray = (data = [], childrenKey = 'children') => data.reduce(
    (acc, item) => acc.concat(item, ...getFlattenArray(item[childrenKey] || [])),
    []
  );

export const changeTabFavIcon = (companyInfo = {}) => {
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = companyInfo.fav_icon;
};

export const capitalizeFirstLetter = (string = '') => {
  const lowerCaseData = lowerCase(string);
  return startCase(lowerCaseData);
};

export function getSearchParamsObj(searchParams) {
  return Object.fromEntries(searchParams);
}

export function getSorting(order, orderBy) {
  if (orderBy === 'created_at') {
    if (order === 'desc') {
      return (a, b) => {
        if (new Date(a[orderBy]) < new Date(b[orderBy])) {
          return -1;
        }
        if (new Date(a[orderBy]) > new Date(b[orderBy])) {
          return 1;
        }
        return 0;
      };
    }
    return (a, b) => {
      if (new Date(a[orderBy]) > new Date(b[orderBy])) {
        return -1;
      }
      if (new Date(a[orderBy]) < new Date(b[orderBy])) {
        return 1;
      }
      return 0;
    };
  }

  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

export const getPaginationData = location => {
  const query = new URLSearchParams(location.search);
  const rowsPerPage = query.get('rows_per_page') || '20';
  const page = query.get('page') || '0';

  return { rowsPerPage: +rowsPerPage, page: +page, query };
};

export const handleRequestSort = (event, property, tableState, setTableState) => {
  const orderBy = property;
  let order = 'desc';

  if (tableState.orderBy === property && tableState.order === 'desc') {
    order = 'asc';
  }
  setTableState(prevObj => ({
    ...prevObj,
    order,
    orderBy,
  }));
};

export const isWordExcelFile = (type = '') => {
  if (type?.includes('doc') || type?.includes('docx') || type?.includes('xlsx')) {
    return true;
  }

  return false;
};

export const isPdfDocFile = (type = '') => {
  if (type?.includes('pdf') || type?.includes('text')) {
    return true;
  }

  return false;
};

export function getSearchParamsList(searchParams) {
  return Object.fromEntries(searchParams);
}

export const handlePrint = componentRef => {
  const content = componentRef.current || null;

  const iframe = document.createElement('iframe');
  document.body.appendChild(iframe);

  const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
  iframeDoc.open();

  // Write the content of the component into the iframe document
  iframeDoc.write(`
    <!DOCTYPE html>
    <html>
      <head>
        <title>Expense</title>
        <style>
          /* Include your stylesheets here */
          /* Example: */
          body {
            font-family: Arial, sans-serif;
          }
          /* Include any other styles you want to apply to the printed content */
        </style>
      </head>
      <body>
        ${content.innerHTML || null}
      </body>
    </html>
  `);
  iframeDoc.close();

  iframe.onload = () => {
    iframe.contentWindow.focus();
    iframe.contentWindow.print();

    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 1000);
  };
};

export async function fileToBase64(url) {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = error => {
        reject(error);
      };
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error fetching file as base64:', error);
    throw error;
  }
}

export const changeQueryParameters = (query, queryObject) => {
  Object.entries(queryObject).forEach(([key, value]) => {
    if (value) {
      query.set(key, value);
    } else {
      query.delete(key);
    }
  });
  return query.toString();
};

export const deleteQueryParameter = (query, queryKey) => {
  query.delete(queryKey);
  return query.toString();
};

export const getCurrenciesOptions = optionsArray => optionsArray.map(item => ({
    value: item.currency_code,
    label: `${item.currency_code} - ${item.currency_name}`,
  }));

export const getSalesPersonsOptions = optionsArray => optionsArray.map(item => ({
    value: `${item.sales_person_name}`,
    label: item.sales_person_name,
  }));

export const getPaymentTermsOptions = optionsArray => optionsArray.map(item => ({
    value: item.term_name,
    label: item.term_name,
  }));

export const getPlaceOfSupplyOptions = optionsArray => optionsArray.map(item => ({
    value: item.place_name,
    label: item.place_name,
  }));

export const getTaxTreatmentsOptions = optionsArray => optionsArray.map(item => ({
    value: item.lookup_name,
    label: item.lookup_name,
    description: item.description,
  }));

export const getPurOrdersOptions = optionsArray => optionsArray.map(item => ({
    value: `${item.id}`,
    label: item.pur_order_formatted_number,
  }));

export const getTitleOptions = optionsArray => optionsArray.map(title => ({
    value: title.lookup_name,
    label: title.lookup_name,
  }));

export const getCountryOptions = optionsArray => optionsArray.map(country => ({
    value: country.lookup_name,
    label: country.lookup_name,
  }));

export const getSuppliersOptions = optionsArray => optionsArray
    .filter(supplier => supplier.is_active)
    .sort(getSorting('desc', 'supplier_name'))
    .map(supplier => ({
      value: `${supplier.id}`,
      label: supplier.supplier_name,
    }));
