import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { borderGrey } from 'styles/mui/themeVariables';
import { searchBarStyling } from 'styles/mui/components/topbar';
import { Autocomplete, TextField, Box, Button } from '@mui/material';
import { searchOptions } from '../utilities/data';

function SearchBar() {
  const inputRef = useRef();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const query = new URLSearchParams(search);

  const searchQuery = query.get('query');

  const [selectedPage, setSelectedPage] = useState(searchOptions[1]);
  const [searchText, setSearchText] = useState(searchQuery || '');
  const [showResetBtn, setResetBtn] = useState(!!searchQuery);

  useEffect(() => {
    if (!searchQuery) {
      setResetBtn(false);
      setSearchText('');
    }
  }, [searchQuery]);

  useEffect(() => {
    const currentPage = searchOptions.find(item => pathname.includes(item.link));

    if (currentPage) {
      setSelectedPage(currentPage);
    } else {
      setSelectedPage(searchOptions[1]);
    }
  }, [pathname]);

  const handleSearch = useCallback(keyCode => {
    if (keyCode === 13) {
      if (searchText) {
        navigate({
          pathname: `/pages/accounting/${selectedPage.link}`,
          search: `query=${searchText}`,
        });
        setResetBtn(true);
      } else {
        navigate(`/pages/accounting/${selectedPage.link}`);
        setResetBtn(false);
      }
    }
  }, [searchText]);

  const handleReset = () => useCallback(() => {
    navigate(`/pages/accounting/${selectedPage.link}`);
    setSearchText('');
    setResetBtn(false);
  }, [selectedPage]);

  const handleChange = (event, newValue) => {
    setSelectedPage(newValue);
  };

  return (
    <Box style={searchBarStyling}>
      <Autocomplete
        sx={
          [{
            width: '300px',
          },
          {
            '& input': {
              fontSize: '12px',
              padding: '0px !important',
              color: theme => theme.palette.greyColor.contrastText,
            },
            '& fieldset': {
              border: 'none',
            }
          }
          ]
        }
        disablePortal
        options={searchOptions}
        getOptionLabel={option => option.label}
        value={selectedPage}
        onChange={handleChange}
        renderInput={params => (
          <TextField
            {...params}
            placeholder="Search..."
            variant="outlined"
          />
        )}
      />
      <TextField
        sx={
          [
            {
              '& input': {
                padding: '5px'
              },
              '& fieldset': {
                border: 'none',
                borderLeft: `1px solid ${borderGrey}`,
                borderRadius: '0px'
              }
            }
          ]
        }
        name="search"
        autoComplete="off"
        placeholder={`Search in ${selectedPage?.label || 'app'}`}
        ref={inputRef}
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
        onKeyUp={e => handleSearch(e.keyCode)}
        InputProps={{ style: { padding: '0px !important', fontSize: '12px' } }}
      />
      {showResetBtn && (
        <Button
          variant="contained"
          tabIndex={0}
          onClick={handleReset}
        >
          Reset
        </Button>
      )}
    </Box>
  );
}

export default SearchBar;
