import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useGetInternalMeetingByIdQuery, useGetInternalMeetingsQuery } from 'services/private/internalMeeting';
import { GET_INTERNAL_MEETING, GET_INTERNAL_MEETINGS } from 'store/slices/internalMeetingSlice';
import Calendar from 'shared/components/Calendar';
import MeetingForm from './MeetingForm';
// import events from './events';

function InternalMeetingsCalendar() {
  const [modal, setModal] = useState(false);
  const { data: internalMeetingsData, isFulfilled: internalMeetingsFetched } = useGetInternalMeetingsQuery();

  const toggle = () => setModal(!modal);

  const dispatch = useDispatch();
  const {
    internalMeeting: { meetings },
    auth: { user },
  } = useSelector(state => state);
  const isStaff =
    Boolean(user.groups.length) && user.groups[0].name.includes('staff');

  useEffect(() => {
    const userOwnMeetings = internalMeetingsData?.user_own_meetings
      ? internalMeetingsData?.user_own_meetings
      : [];

    const userParticipatedMeetings = internalMeetingsData?.user_participated_meetings
      ? internalMeetingsData?.user_participated_meetings
      : [];

    dispatch(GET_INTERNAL_MEETINGS([...userOwnMeetings, ...userParticipatedMeetings]));
  }, [internalMeetingsFetched]);

  const userOwnMeetings = meetings
    ? meetings.map(item => ({
      id: item.id,
      title: item.title,
      description: item.description,
      start: new Date(`${item.meeting_date} ${item.from_time}`),
      end: new Date(`${item.meeting_date} ${item.to_time}`),
    }))
    : [];

  const handleSelectEvent = async event => {
    const { data: internalMeetingByIdData, isFulfilled: internalMeetingByIdFetched } = await useGetInternalMeetingByIdQuery(event.id);
    if (internalMeetingByIdFetched) {
      dispatch(GET_INTERNAL_MEETING(internalMeetingByIdData));
    }
    setModal(true);
  };

  return (
    <>
      <Modal
        className="  modal-dialog--custom"
        isOpen={modal}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>
          {isStaff ? 'Meeting Details' : 'Edit Meeting'}
        </ModalHeader>
        <ModalBody>
          <div>
            <MeetingForm toggle={toggle} />
          </div>
        </ModalBody>
      </Modal>
      <Card>
        <CardBody>
          <Calendar
            events={userOwnMeetings}
            onSelectEvent={handleSelectEvent}
          />
        </CardBody>
      </Card>
    </>
  );
}

export default InternalMeetingsCalendar;
