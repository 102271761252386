/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { linkBlue } from 'styles/mui/themeVariables';
import { megamenuStyling, backdropStyling } from 'styles/mui/components/topbar';
import { toolbarStyling } from 'styles/mui/components/forms';
import { Tooltip, Paper, IconButton, Box, Typography, Button, List, ListItem, ListItemAvatar, ListItemText, Avatar, Backdrop } from '@mui/material';
import { notifications } from 'containers/common/utilities/data';
import ava from 'assets/img/avatar.png';

export default function TopbarNotification() {
  const [collapse, setCollapse] = useState(false);

  const toggle = () => {
    setCollapse(!collapse);
  };

  return (
    <>
      <Tooltip
        title="Notifications"
        placement="bottom"
        onClick={toggle}
      >
        <IconButton
          sx={{ width: '50px', height: '50px', marginTop: '7px' }}
        >
          <NotificationsActiveOutlinedIcon />
        </IconButton>
      </Tooltip>
      {
        collapse && (
          <>
            <Paper sx={{ ...megamenuStyling, left: '-70%', width: '300px' }}>
              <Box sx={toolbarStyling}>
                <Typography variant="p">Notifications</Typography>
                <Button sx={{ color: linkBlue, fontSize: '11px' }} variant="text">
                  Mark all as read
                </Button>
              </Box>
              <List>
                {notifications.map((notification, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar alt="" src={ava} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={(
                        <>
                          <Typography variant="p" sx={{ color: linkBlue, fontSize: '12px' }}> {notification.name}</Typography>
                          <Typography variant="p" sx={{ fontSize: '12px' }}> {notification.message}</Typography>
                        </>
                      )}
                    />
                  </ListItem>
                ))}
              </List>
              <Box sx={{ textAlign: 'center' }}>
                <Link
                  to="/dashboard_default"
                  onClick={toggle}
                >
                  <p style={{ color: linkBlue }}>
                    See all notifications
                  </p>

                </Link>
              </Box>
            </Paper>
            <Backdrop
              open
              sx={backdropStyling}
              onClick={() => setCollapse(false)}
            />
          </>
        )
      }
    </>
  );
}
