/* eslint-disable object-shorthand */
import { privateAPI } from './index';

export const internalMeetingAPI = privateAPI.injectEndpoints({
  endpoints: build => ({
    getInternalMeetings: build.query({
      query: () => 'InternalMeeting/internal_meeting_participants_list/',
    }),
    getInternalMeetingById: build.query({
      query: id => `InternalMeeting/${id}`,
    }),
  }),
});

export const {
  useAddInternalMeetingMutation,
  useGetInternalMeetingsQuery,
  useGetInternalMeetingByIdQuery,
} = internalMeetingAPI;
