/* eslint-disable object-shorthand */
import { useSelector } from 'react-redux';
import { privateAPI } from './index';

export const usersAPI = privateAPI.injectEndpoints({
  endpoints: build => ({
    getRecentActivities: build.query({
      query: () => 'user/recent/activity/',
    }),
    recordActivity: build.mutation({
      query: values => {
        const { id } = useSelector(state => state.auth.user);

        const data = {
          type: values.type,
          description: values.description,
          title: values.title,
          object_id: values.id,
          user: id,
        };

        return {
          url: 'user/recent/activity/',
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const { useGetRecentActivitiesQuery, useRecordActivityMutation } = usersAPI;
