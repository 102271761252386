export const mainWrapperStyles = {
  marginTop: '4rem',
  background: '#80808024',
  minHeight: 'calc(100vh - 4rem)',
  width: '100%',
  maxWidth: '100%',
  overflowX: 'hidden',
};

export const modalCommonStyling = {
  maxWidth: '450px',
  margin: 'auto',
  marginTop: '3rem',
  outline: 'none',
  border: 'none',
};

export const test = '';
