import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Stack,
  Box,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import PersonIcon from '@mui/icons-material/Person';
import FileIcon from 'mdi-react/FileIcon';
import FileDocumentBoxIcon from 'mdi-react/FileDocumentBoxIcon';

import { useGetRecentActivitiesQuery } from 'services/private/users';
import { GET_RECENT_ACTIVITY } from 'store/slices/usersSlice';
import { flexJustifyBetween } from 'styles/mui/utilis/utilis';
import getLinkAddress from 'utilities/getLinkAddress';

function RecentActivity() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const dispatch = useDispatch();
  const { recentActivity = [] } = useSelector(state => state.users);

  const { enqueueSnackbar } = useSnackbar();

  const { data = {} } = useGetRecentActivitiesQuery();

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    (async () => {
      try {
        dispatch(GET_RECENT_ACTIVITY(data.results));
      } catch (error) {
        enqueueSnackbar(error, { variant: 'error' });
      }
    })();
  }, []);

  const getActivityIcon = type => {
    switch (type) {
      case 'Invoice' || 'Invoice Payment' || 'Credit Note' || 'Payment Made':
        return <FileIcon size={28} color="#808080" />;
      case 'Account' || 'Customer' || 'Supplier':
        return <PersonIcon size={28} color="#808080" />;
      default:
        return <FileDocumentBoxIcon size={28} color="#808080" />;
    }
  };

  return (
    <>
      <Tooltip title="Recent Activity" placement="bottom">
        <IconButton onClick={handleMenuClick}>
          <HistoryOutlinedIcon />
        </IconButton>
      </Tooltip>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {recentActivity.slice(0, 10).map(activity => (
          <MenuItem
            onClick={() => {
              handleClose();
            }}
          >
            <Link to={getLinkAddress(activity.type, activity.object_id)}>
              <Box sx={{ ...flexJustifyBetween, gap: '10px' }}>
                <div>{getActivityIcon(activity.type)}</div>
                <Stack>
                  <Typography variant="h5">{activity.title}</Typography>
                  <Typography variant="span">{activity.type}</Typography>
                </Stack>
              </Box>
            </Link>
          </MenuItem>
        ))}

        {recentActivity.length === 0 && (
          <MenuItem>
            <span>No activity found</span>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

export default RecentActivity;
