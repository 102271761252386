import React from 'react';
import useAuth from 'customHooks/useAuth';
import AccountingDashboard from './components/accounting';
import HRDashboard from './components/HR';

function Dashboard() {
  const {
    role: { isAccountingDeptUser },
  } = useAuth();

  const renderRoleBasedDashboard = () => {
    if (isAccountingDeptUser) return <AccountingDashboard />;

    return <HRDashboard />;
  };
  return <>{renderRoleBasedDashboard()}</>;
}

export default Dashboard;
