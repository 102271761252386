import React, { useMemo, useState } from 'react';
import { Card, Box, CardContent, Grid, Menu, MenuItem, Button, Typography } from '@mui/material';
import { flexJustifyBetween, flexCenter, flexAlignCenter } from 'styles/mui/utilis/utilis';
import { borderGrey } from 'styles/mui/themeVariables';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';
import { useGetIncomeExpenseDataQuery } from 'services/private/dashboard';
import formatAmount, { abbreviateAmount } from 'utilities/formatAmount';
import GlobalLoader from 'containers/common/loaders/GlobalLoader';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { expenseBoxStyle, incomeBoxStyle } from 'styles/mui/containers/dashboardStyle';
import { durationOptions } from '../../../utilis/data';

function IncomeAndExpense() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [duration, setDuration] = useState('this fiscal year');
  const { data: incomeExpenseData, isLoading } = useGetIncomeExpenseDataQuery(duration);

  const graphData = useMemo(() => (incomeExpenseData
    ? incomeExpenseData.month_year_list.map(item => ({
      month: `${item.month} ${item.year}`,
      Income: item.income,
      Expense: item.expense,
    }))
    : []), [incomeExpenseData]);

  return (
    <Grid item md={12} lg={6} xl={6}>
      <Card sx={{ padding: '10px' }}>
        <Box sx={{ ...flexJustifyBetween, }}>
          <Typography variant="h4">Income And Expense</Typography>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {duration}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {durationOptions.map(item => (
              <MenuItem
                key={item.value}
                onClick={() => {
                  setDuration(item.value);
                  handleClose();
                }}
                active={item.value === duration}
              >
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <CardContent style={{ minHeight: isLoading ? 500 : '' }}>
          {
            isLoading ?
              (
                <Box sx={{ ...flexCenter, height: 500 }}>
                  <GlobalLoader isLoading={isLoading} />
                </Box>
              ) :
              (
                <>
                  <ResponsiveContainer height={350}>
                    <BarChart
                      width={700}
                      height={300}
                      data={graphData}
                      margin={{
                        top: 20,
                        right: 40,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis tickFormatter={value => abbreviateAmount(value)} />
                      <Tooltip formatter={value => `AED${formatAmount(value)}`} />
                      <ReferenceLine y={0} stroke="#B3B3B3" />
                      <Bar maxBarSize={15} dataKey="Income" fill="#A8EB96" />
                      <Bar maxBarSize={15} dataKey="Expense" fill="#F2D972" />
                    </BarChart>
                  </ResponsiveContainer>
                  <Box sx={{ borderTop: `1px solid ${borderGrey}` }}>
                    <Grid container>
                      <Grid item xs={3} className="border-right">
                        <Box sx={flexAlignCenter}>
                          <span style={incomeBoxStyle} />
                          <Typography variant="body1">Income</Typography>
                        </Box>
                        <Box sx={flexAlignCenter}>
                          <span style={expenseBoxStyle} />
                          <Typography variant="body1">Expense</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={9}>
                        <Grid sx={{ marginLeft: '10px' }} container>
                          <Grid item xs={6}>
                            <Typography variant="successText">Total Income</Typography>
                            <Typography variant="h4">
                              AED
                              {incomeExpenseData &&
                                formatAmount(incomeExpenseData.total_income)}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="successText">Total Expenses</Typography>
                            <Typography variant="h4">
                              AED
                              {incomeExpenseData &&
                                formatAmount(incomeExpenseData.total_expense)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )
          }

        </CardContent>
      </Card>
    </Grid>
  );
}

export default IncomeAndExpense;
