import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthInfo from 'customHooks/useAuthInfo';
import { IconButton, Tooltip, Grid, Typography, Backdrop } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import PaidIcon from '@mui/icons-material/Paid';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import { megamenuStyling } from 'styles/mui/components/topbar';
import { salesListData, generalListData, purchasesListData } from 'containers/common/utilities/data';
import MegaMenuPaper from './MegaMenuPaper';

function QuickCreateMenu() {
  const [showMenu, setShowMenu] = useState(false);
  const {
    isAccountingStaff,
    isStaffPayable,
    isStaffReceivable,
  } = useAuthInfo();

  const navigate = useNavigate();

  return (
    <>
      <Tooltip title="Quick Create" placement="left">
        <IconButton
          variant="contained"
          sx={{
            transform: showMenu ? 'rotate(45deg)' : 'rotate(0deg)',
            transition: 'transform 0.1s',
            '&:hover': {
              backgroundColor: theme => theme.palette.primary.main,
            },
            backgroundColor: theme => theme.palette.primary.main,
            color: theme => theme.palette.primary.contrastText,
            padding: '0px'
          }}
          onClick={() => setShowMenu(!showMenu)}
        >
          <AddOutlinedIcon />
        </IconButton>
      </Tooltip>
      {showMenu && (
        <>
          <MegaMenuPaper sx={megamenuStyling} variant="megaMenu" elevation={24}>
            <Grid spacing={2} container>
              {!isAccountingStaff && (
                <Grid item xl={4} lg={4} md={4}>
                  <Typography variant="h5"><MenuOutlinedIcon /> General </Typography>
                  {
                    generalListData.map(item => (
                      <Typography
                        variant="h6"
                        onClick={() => {
                          setShowMenu(false);
                          navigate(item.path);
                        }}
                      >
                        <AddOutlinedIcon />
                        {item.title}
                      </Typography>
                    ))
                  }
                </Grid>
              )}
              {!isStaffPayable && (
                <Grid item xl={4} lg={4} md={4}>
                  <Typography variant="h5"><PaidIcon /> Sales </Typography>
                  {
                    salesListData.map(item => (
                      <Typography variant="h6" onClick={() => navigate(item.path)}>
                        <AddOutlinedIcon />
                        {item.title}
                      </Typography>
                    ))
                  }
                </Grid>
              )}
              {!isStaffReceivable && (
                <Grid item xl={4} lg={4} md={4}>
                  <Typography variant="h5"><ShoppingCartIcon /> Purchases </Typography>
                  {
                    purchasesListData.map(item => (
                      <Typography variant="h6" onClick={() => navigate(item.path)}>
                        <AddOutlinedIcon />
                        {item.title}
                      </Typography>
                    ))
                  }
                </Grid>
              )}
            </Grid>
          </MegaMenuPaper>
          <Backdrop
            sx={{ color: '#fff', backgroundColor: 'transparent', zIndex: '1' }}
            open
            onClick={() => setShowMenu(false)}
          />
        </>
      )}

    </>
  );
}

export default QuickCreateMenu;
