import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import InternalMeetingsCalendar from 'containers/pages/components/InternalMeetings';
import { useGetNewlyHiredEmployeesQuery, useGetLatestMeetingsQuery, useGetRecentLeavesQuery } from 'services/private/dashboard';
import { GET_NEWLY_HIRED_USERS, GET_LATEST_MEETINGS, GET_RECENT_LEAVES, CLEAR_DASHBOARD_DATA } from 'store/slices/dashboardSlice';
import LeavesTable from './components/LeavesTable';
import UserCard from './components/UserCard';
import MeetingCard from './components/MeetingCard';
// import {
//   getNewlyHiredEmployees,
//   getLatestMeetings,
//   getRecentLeaves,
//   clearDashboardData,
// } from '../../../redux/actions/dashboardActions';

function HRDashboard() {
  const dispatch = useDispatch();
  const { data: newlyHiredEmployeesData, isFulfilled: newlyHiredEmployeesFetched } = useGetNewlyHiredEmployeesQuery();
  const { data: latestMeetingsData, isFulfilled: latestMeetingsFetched } = useGetLatestMeetingsQuery();
  const { data: recentLeavesData, isFulfilled: recentLeavesFetched } = useGetRecentLeavesQuery();
  const { newlyHiredUsers, latestMeetings, recentLeaves } = useSelector(
    state => state.dashboard
  );
  useEffect(() => {
    dispatch(GET_NEWLY_HIRED_USERS(newlyHiredEmployeesData));
    dispatch(GET_LATEST_MEETINGS(latestMeetingsData));
    dispatch(GET_RECENT_LEAVES(recentLeavesData));

    return () => {
      dispatch(CLEAR_DASHBOARD_DATA());
    };
  }, [newlyHiredEmployeesFetched, latestMeetingsFetched, recentLeavesFetched]);

  return (
    <Container className="hr-dashboard mt-3">
      <Row className="mb-4">
        <Col md={12}>
          <h3 className="ml-3 mt-2">Dashboard</h3>
        </Col>
      </Row>
      <Row>
        <Col md={7} className="mb-4 dashboard-left-section">
          <Row>
            <Col>
              <h4 className="mb-3 ml-3">New Employees</h4>
            </Col>
          </Row>
          <Row className="ml-3 newly-hired-employees hrDashboardInfoSection">
            {newlyHiredUsers &&
              newlyHiredUsers.length > 0 &&
              newlyHiredUsers.map(user => (
                <UserCard user={user} key={user.email} />
              ))}
          </Row>
          <Row>
            <Col>
              <h4 className="my-3 ml-3">Meetings Schedule</h4>
            </Col>
          </Row>
          <Row className="hr-dashboard-calender hrDashboardInfoSection">
            <Col className="ml-3">
              <InternalMeetingsCalendar />
            </Col>
          </Row>
        </Col>
        <Col md={5} className="dashboard-right-section">
          <Row>
            <Col>
              <h4 className="mb-3 ml-3">Most Recent Meetings</h4>
            </Col>
          </Row>
          <Row className="mx-3 upcoming-meetings hrDashboardInfoSection">
            <Col className="px-0">
              {latestMeetings && latestMeetings.length > 0 ? (
                <div>
                  {latestMeetings.map(meeting => (
                    <MeetingCard meeting={meeting} key={meeting.id} />
                  ))}
                </div>
              ) : (
                <div className="h-100 d-flex justify-content-center align-items-center">
                  No meeting found
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 className="my-3 ml-3">Most Recent Leaves</h4>
            </Col>
          </Row>
          <Row className="mx-3 hrDashboardInfoSection">
            <Col className="px-0">
              <LeavesTable recentLeaves={recentLeaves} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default HRDashboard;
