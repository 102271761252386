import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { AvatarGroup, Avatar } from '@mui/material';
import { STORAGE_URL } from 'utilities/constants';
import defaultImg from 'assets/img/avatar.png';

function MeetingCard({ meeting }) {
  return (
    <Link to="/pages/manageInternalMeetings">
      <div className="hr-dashboard__meeting-card">
        <div className="meeting-time">
          {meeting.from_time
            ? moment(meeting.from_time, 'hh:mm:ss').format('hh:mm A')
            : ''}
        </div>
        <div className="meeting-remaining-time">
          {meeting.meeting_date && meeting.from_time
            ? moment(`${meeting.meeting_date} ${meeting.from_time}`).fromNow()
            : ''}
        </div>
        <div className="meeting-name">{meeting && meeting.title}</div>
        <div className="meeting-members">
          <AvatarGroup max={2}>
            {meeting.participants && meeting.participants.length > 0 ? (
              meeting.participants.map(user => (
                <Avatar
                  alt="Participant"
                  src={`${STORAGE_URL}${user.photo}`}
                  key={user.photo}
                />
              ))
            ) : (
              <>
                <Avatar alt="Participant" src={defaultImg} />
                <Avatar alt="Participant" src={defaultImg} />
              </>
            )}
          </AvatarGroup>
        </div>
      </div>
    </Link>
  );
}

MeetingCard.propTypes = {
  meeting: PropTypes.object.isRequired,
};

export default MeetingCard;
