import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Toolbar, Box } from '@mui/material';
import { isEmpty } from 'lodash';
import { topbarStyling, topbarRightStyling, topbarMiddleStyling, topbarLeftStyling } from 'styles/mui/components/topbar';
import SearchBar from 'containers/common/components/SearchBar';
import useAuthInfo from 'customHooks/useAuthInfo';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarNotification from './TopbarNotification';
import QuickCreateMenu from './QuickCreateMenu';
import RecentActivity from './RecentActivity';
import TopbarSettings from './TopbarSettings';

function Topbar() {
  const { pathname } = useLocation();
  const { companyDetail: companyInfo, department } = useAuthInfo();

  const isLogoUrlEmpty = isEmpty(companyInfo?.company_logo);

  return (
    <Toolbar sx={{ ...topbarStyling, backgroundColor: 'white' }}>
      <Box sx={topbarLeftStyling}>
        <TopbarSidebarButton />
        <Link
          style={{ backgroundImage: isLogoUrlEmpty ? '' : 'none' }}
          to="/"
          className="d-flex align-items-center"
        >
          {!isLogoUrlEmpty && (
            <img style={{ maxHeight: '60px', maxWidth: '60px' }} src={companyInfo.company_logo} alt="" />
          )}
        </Link>
      </Box>
      {/* allowSystemAccess && */}
      {department === 'finance' && (
        !pathname.includes('/pages/reports/') && (
          <Box sx={topbarMiddleStyling}>
            <div>
              <QuickCreateMenu />
              <RecentActivity />
            </div>
            <SearchBar />
          </Box>
        )
      )}
      <Box sx={topbarRightStyling}>
        <TopbarNotification />
        {/* allowSystemAccess && */}
        {(department === 'finance' || department === 'hr') && (
          <TopbarSettings department={department} />
        )}
        <TopbarProfile />
      </Box>
    </Toolbar>
  );
}

export default Topbar;
