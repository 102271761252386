/* eslint react/forbid-prop-types: 0 */
/* eslint no-script-url: 0 */

import React, { useState } from 'react';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { IconButton, Menu, MenuItem, Box, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

function TopbarSettings(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { department } = props;
  const navigate = useNavigate();
  const isHrDepart = department === 'hr';

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggle = () => {
    if (isHrDepart) navigate('/pages/settings');
  };
  return (
    <Box sx={{ marginTop: '10px' }}>
      <Tooltip title="Settings">
        <IconButton
          onClick={handleMenuClick}
        >
          <SettingsOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            toggle();
            navigate('/pages/accounting/openingBalance');
            handleClose();
          }}
        >
          Opening Balance
        </MenuItem>
        <MenuItem
          onClick={() => {
            toggle();
            navigate('/pages/accounting/stampAndSignature');
            handleClose();
          }}
        >
          Stamp and Signature
        </MenuItem>
        <MenuItem
          onClick={() => {
            toggle();
            navigate('/pages/accounting/uploadSignature');
            handleClose();
          }}
        >
          Requester Signature
        </MenuItem>
      </Menu>
    </Box>
  );
}
TopbarSettings.propTypes = {
  department: PropTypes.string.isRequired,
};
export default TopbarSettings;
