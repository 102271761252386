import React from 'react';
import { Grid, Typography } from '@mui/material';
import TotalReceiveables from './components/TotalReceiveables';
import TotalPayables from './components/TotalPayables';
import IncomeAndExpense from './components/IncomeAndExpense';
import TopExpenses from './components/TopExpenses';

function AccountingDashboard() {
  return (
    <Grid container>
      <Typography variant="pageTitle">
        Dashboard
      </Typography>

      <Grid spacing={2} sx={{ marginBottom: '20px' }} container>
        <TotalReceiveables />
        <TotalPayables />
        <IncomeAndExpense />
        <TopExpenses />
      </Grid>
    </Grid>
  );
}

export default AccountingDashboard;
