import React from 'react';
// import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import { Formik, Form } from 'formik';
// import * as Yup from 'yup';
// import PropTypes from 'prop-types';
// import moment from 'moment';
// import { Button, ButtonToolbar, Modal } from 'reactstrap';
// import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
// import {
//   addInternalMeeting,
//   editInternalMeeting,
//   clearInternalMeeting,
//   deleteInternalMeeting,
// } from '../../../redux/actions/internalMeetingsActions';
// import { getHolidayDates } from '../../../redux/actions/companyHolidaysActions';
// import { getUsersByStatus } from '../../../redux/actions/usersActions';
// import { returnErrors } from '../../../redux/actions/messagesActions';
// import { FormikField } from 'shared/form/Field';
// import FormikDatePicker from 'shared/form/FormikDatePicker';
// import FormikTimePicker from 'shared/form/FormikTimePicker';
// import FormikMultiSelect from 'shared/form/FormikMultiSelect';

// const initValues = {
//   id: '',
//   from_time: moment('00:00:00', ['h:mm:ss']),
//   to_time: moment('00:00:00', ['h:mm:ss']),
//   meeting_date: new Date(),
//   title: '',
//   description: '',
//   participants: [],
//   location: '',
// };

function MeetingForm() {
  // {
  //   doAddInternalMeeting,
  //   doEditInternalMeeting,
  //   doClearInternalMeeting,
  //   doDeleteInternalMeeting,
  //   doGetHolidayDates,
  //   doReturnErrors,
  //   doGetUsersByStatus,
  //   users,
  //   fetchedUser,
  //   holidayDates,
  //   toggle,
  //   fetched,
  // }
  // )

  //   const [initialValues, setInitialValues] = useState(initValues);
  //   const [deleteModal, setDeleteModal] = useState(false);

  //   const deleteToggle = () => setDeleteModal(prevState => !prevState);
  //   useEffect(() => {
  //     doGetHolidayDates();
  //     doGetUsersByStatus('participants');
  //     return () => {
  //       doClearInternalMeeting();
  //     };
  //   }, []);

  //   useEffect(() => {
  //     if (fetched) {
  //       setInitialValues({
  //         id: fetched.id,
  //         from_time: moment(fetched.from_time, ['h:mm:ss']),
  //         to_time: moment(fetched.to_time, ['h:mm:ss']),
  //         meeting_date: new Date(fetched.meeting_date),
  //         title: fetched.title,
  //         description: fetched.description,
  //         participants: fetched.participants.map(item => `${item}`),
  //         location: fetched.location,
  //       });
  //     }
  //   }, [fetched]);

  //   const participantsOptions = users.map(user => ({
  //     value: `${user.id}`,
  //     label: user.employee_name,
  //   }));

  //   const handleDelete = () => {
  //     if (fetched) {
  //       doDeleteInternalMeeting(fetched.id);
  //       toggle();
  //     }
  //   };

  //   const Icon = <span className="lnr lnr-flag modal__title-icon" />;
  //   const colored = false;
  //   const color = 'warning';
  //   const excludeDates = holidayDates.map(date => new Date(date));
  //   const isStaff =
  //     Boolean(fetchedUser.groups.length) &&
  //     fetchedUser.groups[0].name.includes('staff');

  return (<h1>Meeting Form</h1>);

  //   return (
  //     <>
  //       <Modal
  //         color="warning"
  //         btn="Warning"
  //         isOpen={deleteModal}
  //         toggle={deleteToggle}
  //         className="modal-dialog--warning"
  //       >
  //         <div className="modal__header">
  //           <button
  //             className="lnr lnr-cross modal__close-btn"
  //             type="button"
  //             onClick={deleteToggle}
  //           />
  //           {Icon}
  //           <h4 className="text-modal  modal__title">Attention!</h4>
  //         </div>
  //         <div className="modal__body">
  //           Are you sure you want to delete this meeting?
  //         </div>
  //         <ButtonToolbar className="modal__footer">
  //           <Button className="modal_cancel" onClick={deleteToggle}>
  //             Cancel
  //           </Button>{' '}
  //           <Button
  //             className="modal_ok"
  //             outline={colored}
  //             color={color}
  //             onClick={handleDelete}
  //           >
  //             Ok
  //           </Button>
  //         </ButtonToolbar>
  //       </Modal>
  //       <Formik
  //         enableReinitialize
  //         initialValues={initialValues}
  //         validationSchema={Yup.object({
  //           title: Yup.string()
  //             .max(100, 'Maximum 100 characters allowed.')
  //             .required('Required!'),
  //           from_time: Yup.string().nullable().required('Start Time is required'),
  //           to_time: Yup.string().nullable().required('End Time is required'),
  //           meeting_date: Yup.date()
  //             .nullable()
  //             .required('Meeting Date is required'),
  //           location: Yup.string().max(255, 'Maximum 255 characters allowed.'),
  //           description: Yup.string().max(255, 'Maximum 255 characters allowed.'),
  //         })}
  //         onSubmit={async (values, { setSubmitting, resetForm }) => {
  //           try {
  //             if (fetched) {
  //               await doEditInternalMeeting(values);
  //             } else {
  //               await doAddInternalMeeting(values);
  //             }
  //             setSubmitting(false);
  //             resetForm(initValues);
  //             toggle();
  //           } catch (err) {
  //             doReturnErrors(err.response.data, err.response.status);
  //           }
  //         }}
  //       >
  //         {({
  //           isSubmitting,
  //           touched,
  //           values,
  //           errors,
  //           setFieldTouched,
  //           setFieldValue,
  //           resetForm,
  //         }) => (
  //           <Form className="form form--horizontal my-3 text-left">
  //             <div className="form__form-group">
  //               <span className="form__form-group-label col-lg-4 required">
  //                 Title
  //               </span>
  //               <div className="form__form-group-field col-lg-8">
  //                 <FormikField
  //                   name="title"
  //                   type="text"
  //                   placeholder="Title"
  //                   value={values.title}
  //                   touched={touched.title}
  //                   error={errors.title}
  //                   disabled={isStaff}
  //                 />
  //               </div>
  //             </div>
  //             <div className="form__form-group">
  //               <span className="form__form-group-label text-nowrap col-lg-4 required">
  //                 Start Time
  //               </span>
  //               <div className="form__form-group-field col-lg-8">
  //                 <FormikTimePicker
  //                   name="from_time"
  //                   placeholder="From"
  //                   timeMode
  //                   onChange={setFieldValue}
  //                   onBlur={setFieldTouched}
  //                   value={values.from_time}
  //                   touched={touched.from_time}
  //                   error={errors.from_time}
  //                   disabled={isStaff}
  //                 />
  //               </div>
  //             </div>
  //             <div className="form__form-group">
  //               <span className="form__form-group-label text-nowrap col-lg-4 required">
  //                 End Time
  //               </span>
  //               <div className="form__form-group-field col-lg-8">
  //                 <FormikTimePicker
  //                   name="to_time"
  //                   placeholder="To"
  //                   timeMode
  //                   onChange={setFieldValue}
  //                   onBlur={setFieldTouched}
  //                   value={values.to_time}
  //                   touched={touched.to_time}
  //                   error={errors.to_time}
  //                   disabled={isStaff}
  //                 />
  //               </div>
  //             </div>
  //             <div className="form__form-group">
  //               <span className="form__form-group-label text-nowrap col-lg-4 required">
  //                 Meeting Date
  //               </span>
  //               <label
  //                 htmlFor="meeting_date"
  //                 className="form__form-group-field col-lg-8"
  //               >
  //                 <FormikDatePicker
  //                   name="meeting_date"
  //                   id="meeting_date"
  //                   placeholder="To"
  //                   onChange={setFieldValue}
  //                   onBlur={setFieldTouched}
  //                   value={values.meeting_date}
  //                   touched={touched.meeting_date}
  //                   error={errors.meeting_date}
  //                   excludeDates={excludeDates}
  //                   disabled={isStaff}
  //                 />
  //                 <div className="form__form-group-icon">
  //                   <CalendarBlankIcon />
  //                 </div>
  //               </label>
  //             </div>
  //             <div className="form__form-group">
  //               <span className="form__form-group-label col-lg-4">
  //                 Participants
  //               </span>
  //               <div className="form__form-group-field col-lg-8">
  //                 <FormikMultiSelect
  //                   name="participants"
  //                   type="text"
  //                   options={participantsOptions}
  //                   onChange={setFieldValue}
  //                   onBlur={setFieldTouched}
  //                   value={values.participants}
  //                   touched={touched.participants}
  //                   error={errors.participants}
  //                   disabled={isStaff}
  //                 />
  //               </div>
  //             </div>
  //             <div className="form__form-group">
  //               <span className="form__form-group-label col-lg-4">Location</span>
  //               <div className="form__form-group-field col-lg-8">
  //                 <FormikField
  //                   name="location"
  //                   type="text"
  //                   value={values.location}
  //                   touched={touched.location}
  //                   error={errors.location}
  //                   disabled={isStaff}
  //                 />
  //               </div>
  //             </div>
  //             <div className="form__form-group">
  //               <span className="form__form-group-label col-lg-4">
  //                 Description
  //               </span>
  //               <div className="form__form-group-field col-lg-8">
  //                 <FormikField
  //                   textArea
  //                   name="description"
  //                   type="text"
  //                   value={values.description}
  //                   touched={touched.description}
  //                   error={errors.description}
  //                   disabled={isStaff}
  //                 />
  //               </div>
  //             </div>
  //             {!isStaff && (
  //               <ButtonToolbar className="form__button-toolbar w-100">
  //                 <Button
  //                   color="primary"
  //                   type="submit"
  //                   disabled={isSubmitting}
  //                   className="icon-btn"
  //                 >
  //                   {fetched ? 'Update' : 'Save'}
  //                 </Button>
  //                 <Button
  //                   type="button"
  //                   className="icon-btn"
  //                   disabled={!touched || isSubmitting}
  //                   onClick={() => {
  //                     resetForm(initValues);
  //                     toggle();
  //                   }}
  //                 >
  //                   Cancel
  //                 </Button>
  //                 {fetched && (
  //                   <Button
  //                     color="primary"
  //                     className="icon-btn ml-auto"
  //                     onClick={deleteToggle}
  //                   >
  //                     Delete this Meeting
  //                   </Button>
  //                 )}
  //               </ButtonToolbar>
  //             )}
  //           </Form>
  //         )}
  //       </Formik>
  //     </>
  //   );
}

// MeetingForm.propTypes = {
//   doReturnErrors: PropTypes.func.isRequired,
//   doAddInternalMeeting: PropTypes.func.isRequired,
//   doEditInternalMeeting: PropTypes.func.isRequired,
//   doClearInternalMeeting: PropTypes.func.isRequired,
//   doDeleteInternalMeeting: PropTypes.func.isRequired,
//   doGetUsersByStatus: PropTypes.func.isRequired,
//   doGetHolidayDates: PropTypes.func.isRequired,
//   users: PropTypes.arrayOf(PropTypes.object),
//   holidayDates: PropTypes.arrayOf(PropTypes.string).isRequired,
//   toggle: PropTypes.func.isRequired,
//   fetched: PropTypes.object,
//   fetchedUser: PropTypes.object.isRequired,
// };

// MeetingForm.defaultProps = {
//   users: [],
//   fetched: null,
// };

export default connect(
  state => ({
    fetchedUser: state.auth.user,
    users: state.users.usersByStatus,
    fetched: state.internalMeetings.fetched,
    holidayDates: state.companyHolidays.holidayDates,
  }),
  {
    // doAddInternalMeeting: addInternalMeeting,
    // doEditInternalMeeting: editInternalMeeting,
    // doClearInternalMeeting: clearInternalMeeting,
    // doDeleteInternalMeeting: deleteInternalMeeting,
    // doGetUsersByStatus: getUsersByStatus,
    // doReturnErrors: returnErrors,
    // doGetHolidayDates: getHolidayDates,
  }
)(MeetingForm);
