import { useSelector } from 'react-redux';
import {
  ACCOUNTING_CONTROLLER,
  ACCOUNTING_MANAGER,
  ACCOUNTING_STAFF,
  ACCOUNTING_STAFF_PAYABLE,
  ACCOUNTING_STAFF_RECEIVABLE,
  SUPER_USER,
  HR_MANAGER,
  HR_CONTROLLER,
  HR_STAFF,
} from 'utilities/constants';

function useAuth() {
  const { token, isAuthenticated, isLoading, user } = useSelector(state => state.auth);

  // console.log('user : ', user);
  const targetUserGroup = user ? user.groups[0].name : ''; // Targeting first group
  const isSuperUser = targetUserGroup === SUPER_USER;
  const isAccountingDeptUser =
    targetUserGroup === ACCOUNTING_CONTROLLER ||
    targetUserGroup === ACCOUNTING_MANAGER ||
    targetUserGroup === ACCOUNTING_STAFF ||
    targetUserGroup === ACCOUNTING_STAFF_PAYABLE ||
    targetUserGroup === ACCOUNTING_STAFF_RECEIVABLE;
  const isHRDeptUser =
    targetUserGroup === HR_CONTROLLER ||
    targetUserGroup === HR_MANAGER ||
    targetUserGroup === HR_STAFF;

  return {
    token,
    isAuthenticated,
    isLoading,
    user,
    role: {
      isSuperUser,
      isAccountingDeptUser,
      isHRDeptUser,
    },
  };
}

export default useAuth;
