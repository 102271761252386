import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ListItemButton, ListItemIcon, ListItemText, Collapse, List } from '@mui/material';

import SidebarItem from './SidebarItem';
import { ExpandLessIcon, ExpandMoreIcon } from '../../../icons/index';

function SidebarNested({ title, icon, data }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItemButton onClick={() => setOpen(!open)}>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText primary={title} />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            data.map(link => {
              if (link?.data) {
                return (
                  <SidebarNested
                    title={link.title}
                    data={link.data}
                    icon={link?.icon}
                  />
                );
              }
              return (
                <SidebarItem
                  title={link.title}
                  icon={link.icon}
                  path={link.path}
                  key={link.path}
                />
              );
            })
          }
        </List>
      </Collapse>
    </>
  );
}

SidebarNested.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.element,
  data: PropTypes.array
};

SidebarNested.defaultProps = {
  title: 'Link',
  icon: null,
  data: []
};

export default SidebarNested;
