import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from 'utilities/constants';

// eslint-disable-next-line import/prefer-default-export
export const privateAPI = createApi({
  reducerPath: 'privateAPI',
  tagTypes: [
    'createdItem',
    'itemStatusChanged',
    'deleteItem',
    'editItem',
    'changeBankStatus',
    'importBankStatement',
    'deleteBankAccount',
    'addBankAccount',
    'updateBankAccount',
    'addComment',
    'addCustomer',
    'editCustomer',
    'deleteCustomer',
    'addedChartOfAccount',
    'deleteExpense',
    'addedExpense',
    'editExpense',
    'deleteExpenseDoc',
    'uploadedExpenseFile',
    'deleteBill',
    'addBill',
    'deletePaymentMade',
    'deleteComment',
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: headers => {
      const token = localStorage.getItem('token');

      if (token) headers.set('Authorization', `Token ${token}`);

      return headers;
    },
  }),
  endpoints: () => ({}),
});
