import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  HR_STAFF,
  OPERATION_CONTROLLER,
  OPERATION_MANAGER,
  SUPER_USER,
} from 'utilities/constants';

function useAuthInfo() {
  const companyDetail = JSON.parse(localStorage.getItem('companyInfo'));
  const {
    companyDetail: companyInfo,
    user,
    isAuthenticated,
  } = useSelector(state => state.auth);

  const groups = useMemo(() => user?.groups, [user]);
  const groupName = useMemo(() => user?.groups[0]?.name || null, [user]);
  const id = useMemo(() => user?.id, [user]);
  const email = useMemo(() => user?.email, [user]);
  const profile = useMemo(() => user?.profile, [user]);
  const isStaffPayable = useMemo(() => user?.isStaffPayable, [user]);
  const isStaffReceivable = useMemo(() => user?.isStaffReceivable, [user]);
  const isAccountingStaff = useMemo(() => user?.isAccountingStaff, [user]);
  const isAccountingSupervisor = useMemo(() => user?.isAccountingSupervisor, [user]);
  const department = useMemo(() => user?.profile?.department, [user]);
  const allowSystemAccess = useMemo(() => user?.profile?.system_access, [user]);
  const isInventory = useMemo(() => companyInfo?.is_inventory, [companyInfo]);

  const isOperationsStaff = useMemo(
    () => groupName === OPERATION_CONTROLLER || OPERATION_MANAGER,
    [groupName]
  );
  const isSuperUser = useMemo(() => groups && groups[0] === SUPER_USER, [groups]);
  const isHrStaff = useMemo(() => groups && groups[0] === HR_STAFF, [groups]);
  const isAccountingDept = useMemo(
    () => isAccountingSupervisor || isAccountingStaff,
    [isAccountingSupervisor, isAccountingStaff]
  );

  const requestorSign = useMemo(() => user?.profile?.requestor_signature, [user]);

  return {
    companyDetail,
    user,
    groups,
    id,
    email,
    profile,
    department,
    isStaffPayable,
    isStaffReceivable,
    isAccountingStaff,
    isAccountingSupervisor,
    isAuthenticated,
    allowSystemAccess,
    isSuperUser,
    isHrStaff,
    groupName,
    isOperationsStaff,
    isAccountingDept,
    isInventory,
    requestorSign,
  };
}

export default useAuthInfo;
