import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    meetings: [],
    fetched: null,
};

const internalMeetingSlice = createSlice({
    name: 'internalMeeting',
    initialState,
    reducers: {
        GET_INTERNAL_MEETING: (state, action) => ({ ...state, fetched: action.payload }),
        GET_INTERNAL_MEETINGS: (state, action) => ({ ...state, meetings: action.payload }),
    },
});

export const { GET_INTERNAL_MEETINGS, GET_INTERNAL_MEETING } = internalMeetingSlice.actions;
export default internalMeetingSlice.reducer;
