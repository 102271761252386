import React from 'react';
import PropTypes from 'prop-types';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function SidebarItem({ title, icon, path }) {
    const navigate = useNavigate();
    return (
        <ListItemButton onClick={() => navigate(path)}>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText primary={title} />
        </ListItemButton>
    );
}

SidebarItem.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.element,
    path: PropTypes.string,
};

SidebarItem.defaultProps = {
    title: 'Link',
    icon: null,
    path: '/',
};

export default SidebarItem;
