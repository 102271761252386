import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery, Drawer } from '@mui/material';

import { toggleSidebar } from 'store/slices/sidebarSlice';
import { sidebarCollapse, sidebarExpand } from 'styles/mui/containers/pages/wrapper';
import SidebarContent from './SidebarContent';

function Sidebar() {
  const dispatch = useDispatch();
  const { collapse } = useSelector(state => state.sidebar);
  const isMobile = useMediaQuery('(max-width:768px)');
  return (
    <> {
      !isMobile ? (
        <div style={!collapse ? sidebarExpand : sidebarCollapse}>
          <SidebarContent />
        </div>
      ) :
        (
          <Drawer sx={sidebarExpand} open={collapse} onClose={() => dispatch(toggleSidebar())}>
            <SidebarContent />
          </Drawer>
        )
    }

    </>
  );
}

export default Sidebar;
