/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import { useMemo } from 'react';
import { ACCOUNTING_STAFF } from 'utilities/constants';
import {
  GroupsIcon,
  CategoryIcon,
  AccountBalanceIcon,
  MonetizationOnIcon,
  ShoppingCartIcon,
  PersonIcon,
  AssessmentIcon,
  MenuBookIcon,
  SavingsIcon,
  EnergySavingsLeafIcon,
  AdminPanelSettingsIcon,
  Diversity2Icon,
  AccountCircleIcon,
  LayersIcon,
} from '../icons/index';

import useAuthInfo from '../../../customHooks/useAuthInfo';
import {
  salesLinks,
  purchasesLinks,
  Dashboard,
  commonSidebarLinks,
  Activities,
  companyLinks,
  financeHrLinks,
  Settings,
  Reports,
  InternalMeetings,
  bookingModuleLinks,
} from '../utilities/data';

const useGetSidebarLinks = () => {
  const {
    isAccountingStaff,
    isAccountingSupervisor,
    isInventory = false,
    department,
    isSuperUser,
    isHrStaff,
    groups,
    isOperationsStaff,
  } = useAuthInfo();
  const accountantLinks = useMemo(
    () => [
      ...(!isAccountingStaff
        ? [
            {
              title: 'Currencies',
              path: '/pages/accounting/accountant/currencies',
              permissions: [],
            },
          ]
        : [isAccountingStaff]),
      {
        title: 'Manual Journals',
        path: '/pages/accounting/accountant/journals',
        permissions: [],
      },
      ...(!isAccountingStaff
        ? [
            {
              title: 'Tax Payments',
              path: '/pages/accounting/accountant/taxPayments',
              permissions: [],
            },
          ]
        : [isAccountingStaff]),
      {
        title: 'Chart of Accounts',
        path: '/pages/accounting/accountant/chartOfAccounts',
        permissions: [],
      },
      {
        title: 'Transaction Locking',
        path: '/pages/accounting/accountant/transactionLocking',
        permissions: [],
      },
    ],
    []
  );

  const accountingSidebarLinks = useMemo(
    () => [
      Dashboard,
      ...(!isAccountingStaff
        ? [
            {
              title: 'Items',
              path: '/pages/accounting/items',
              icon: <CategoryIcon />,
              permissions: [],
            },
          ]
        : [isAccountingStaff]),
      ...(isInventory
        ? [
            {
              title: 'Inventory Adjustments',
              path: '/pages/accounting/inventoryAdjustments',
              icon: <GroupsIcon />,
              permissions: [],
            },
          ]
        : []),
      ...(!isAccountingStaff
        ? [
            {
              title: 'Banking',
              path: '/pages/accounting/banking',
              icon: <AccountBalanceIcon />,
              permissions: [],
            },
          ]
        : [isAccountingStaff]),
      {
        data: salesLinks,
        title: 'Sales',
        path: null,
        icon: <MonetizationOnIcon />,
        permissions: [],
      },
      {
        data: purchasesLinks,
        title: 'Purchases',
        path: null,
        icon: <ShoppingCartIcon />,
        permissions: [],
      },
      {
        data: accountantLinks,
        title: 'Accountant',
        path: null,
        icon: <PersonIcon />,
        permissions: [],
      },
      {
        title: 'Reports',
        path: '/pages/reports',
        icon: <AssessmentIcon />,
        permissions: [],
      },
      {
        title: 'Hotels',
        path: null,
        icon: <MenuBookIcon />,
        permissions: [],
        data: bookingModuleLinks,
      },
    ],
    []
  );

  const subSidebarLinks = useMemo(
    () => [
      ...(isAccountingSupervisor
        ? [
            {
              title: 'Employee Salaries',
              path: '/pages/accounting/salaries',
              icon: <SavingsIcon />,
              permissions: [],
            },
          ]
        : [isAccountingSupervisor]),
      ...(!isAccountingStaff
        ? [
            {
              title: 'Employee Leaves',
              path: '/pages/hr/leaves',
              icon: <EnergySavingsLeafIcon />,
              permissions: [],
            },
          ]
        : [isAccountingStaff]),
      ...commonSidebarLinks,
    ],
    []
  );

  const hrLinks = [
    {
      data: companyLinks,
      title: 'Company',
    },
    {
      title: 'Holidays',
      path: '/pages/hr/companyHolidays',
    },
    {
      title: 'Employees',
      path: '/pages/hr/employees',
    },
    ...(!isHrStaff
      ? [
          {
            title: 'Leaves Request',
            path: '/pages/hr/leaves',
          },
        ]
      : []),
    {
      title: 'Documents Request',
      path: '/pages/hr/employeeRequests',
    },
    {
      title: 'Salaries',
      path: '/pages/hr/salaries',
    },
  ];

  const superUserSidebar = [
    Dashboard,
    ...(isSuperUser || department === 'finance'
      ? [
          {
            data: salesLinks,
            title: 'Accounting',
            icon: <AdminPanelSettingsIcon />,
            path: null,
          },
        ]
      : []),
    ...(isSuperUser || department === 'hr'
      ? [
          Activities,
          {
            data: hrLinks,
            title: 'HR',
            icon: <Diversity2Icon />,
            path: null,
          },
        ]
      : []),
    ...(department === 'finance' && groups[0] !== ACCOUNTING_STAFF
      ? [financeHrLinks]
      : []),
    ...(isSuperUser || department === 'finance' ? [Reports, Settings] : []),
    ...commonSidebarLinks,
  ];

  const newUserSidebar = [
    {
      title: 'My Profile',
      icon: <AccountCircleIcon />,
      path: '/pages/user/profile',
    },
    InternalMeetings,
    ...(isOperationsStaff
      ? [
          {
            title: 'Miscellaneous',
            icon: <LayersIcon />,
            data: [
              {
                title: 'Employee Leaves',
                path: '/pages/hr/leave',
              },
            ],
          },
        ]
      : []),
  ];

  return {
    accountingSidebarLinks,
    subSidebarLinks,
    superUserSidebar,
    newUserSidebar,
  };
};

export default useGetSidebarLinks;
