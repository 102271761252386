import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    newlyHiredUsers: [],
    latestMeetings: [],
    recentLeaves: [],
    totalReceivables: null,
    totalPayables: null,
    incomeExpense: null,
    topExpenses: null,
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        GET_NEWLY_HIRED_USERS: (state, action) => ({ ...state, newlyHiredUsers: action.payload }),
        GET_LATEST_MEETINGS: (state, action) => ({ ...state, latestMeetings: action.payload }),
        GET_RECENT_LEAVES: (state, action) => ({ ...state, recentLeaves: action.payload }),
        GET_TOTAL_RECEIVABLE_DATA: (state, action) => ({ ...state, totalReceivables: action.payload }),
        GET_TOTAL_PAYABLE_DATA: (state, action) => ({ ...state, totalPayables: action.payload }),
        GET_INCOME_EXPENSE_DATA: (state, action) => ({ ...state, incomeExpense: action.payload }),
        GET_TOP_EXPENSES: (state, action) => ({ ...state, topExpenses: action.payload }),
        CLEAR_DASHBOARD_DATA: () => ({ ...initialState }),
    },
});

export const { GET_NEWLY_HIRED_USERS,
    GET_LATEST_MEETINGS,
    GET_RECENT_LEAVES,
    CLEAR_DASHBOARD_DATA,
    GET_TOTAL_PAYABLE_DATA,
    GET_TOTAL_RECEIVABLE_DATA,
    GET_INCOME_EXPENSE_DATA,
    GET_TOP_EXPENSES } = dashboardSlice.actions;
export default dashboardSlice.reducer;
