// API BASE URL
export const BOOKING_API_URL = 'https://sublime.beyonderissolutions.com/api';
// export const API_URL = 'https://dolce-jou-staging.beyonderissolutions.com/api';
export const API_URL = 'https://sublime.beyonderissolutions.com/api';
export const SOCKET_URL = 'wss://stagingcrm.beyonderissolutions.com';
export const STORAGE_URL =
  'https://crm-application-storages.s3.amazonaws.com/media_dolce_jou';

export const ROWS_PER_PAGE = '20';

export const EMAIL_REGEX =
  /^(([^`~!$%^&*?/|}{#=<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// HR ROLES
export const HR_CONTROLLER = 'hr_controller';
export const HR_MANAGER = 'hr_manager';
export const HR_STAFF = 'hr_staff';

// ACCOUNTING ROLES
export const ACCOUNTING_CONTROLLER = 'accounting_controller';
export const ACCOUNTING_MANAGER = 'accounting_manager';
export const ACCOUNTING_STAFF = 'accounting_staff';
export const ACCOUNTING_STAFF_RECEIVABLE = 'accounting_staff_receivable';
export const ACCOUNTING_STAFF_PAYABLE = 'accounting_staff_payable';

// OPERATIONS ROLES
export const OPERATION_CONTROLLER = 'operations_controller';
export const OPERATION_MANAGER = 'operations_manager';

// SUPER USER
export const SUPER_USER = 'superuser';

// luxury explorer main company

export const LUXURY_COMPANY = 'Luxury Events and VIP Travel DMCC';
export const DEFAULT_PAGINATION_DATA = {
  count: 0,
  results: [],
  previous: null,
  next: null,
};

export const IMAGE_PLACEHOLDER = '/img/image-placeholder.png';

// Form State

export const TOGGLE_PAYMENT_TERM_MODAL = 'togglePaymentTermModal';
export const TOGGLE_SUPPLIER_MODAL = 'toggleSupplierModal';
export const TOGGLE_WARNING_MODAL = 'toggleWarningModal';
export const TOGGLE_INFO_POPUP = 'toggleInfoPopup';
export const UPDATE_LAST_REF_NUM = 'updateLastReferenceNumber';
export const UPDATE_TAX_RATE = 'updateDefaultTaxRate';
export const TOGGLE_ACCOUNT_MODAL = 'toggleAccountModal';
