import React from 'react';
import Topbar from './components/topbar/Topbar';
import Sidebar from './components/sidebar/Sidebar';

function LayoutWrapper() {
  return (
    <div style={{ backgroundColor: 'white', }}>
      <Topbar />
      <Sidebar />
    </div>
  );
}

export default LayoutWrapper;
