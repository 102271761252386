import { configureStore } from '@reduxjs/toolkit';
import { serviceMiddlewares, serviceReducers } from 'services';
import authSlice from './slices/authSlice';
import sidebarSlice from './slices/sidebarSlice';
import usersSlice from './slices/usersSlice';
import dashboardSlice from './slices/dashboardSlice';
import internalMeetingSlice from './slices/internalMeetingSlice';
import reportsSlice from './slices/reportsSlice';
import itemsSlice from './slices/itemsSlice';
import bankingSlice from './slices/bankingSlice';
import taxRatesSlice from './slices/taxRatesSlice';
import paymentTermsSlice from './slices/paymentTermsSlice';
import salesAccountSlice from './slices/salesAccountSlice';
import paymentModesSlice from './slices/paymentModesSlice';

const store = configureStore({
  reducer: {
    auth: authSlice,
    sidebar: sidebarSlice,
    users: usersSlice,
    dashboard: dashboardSlice,
    internalMeeting: internalMeetingSlice,
    reports: reportsSlice,
    items: itemsSlice,
    banking: bankingSlice,
    taxRates: taxRatesSlice,
    paymentTerms: paymentTermsSlice,
    salesAccount: salesAccountSlice,
    paymentModes: paymentModesSlice,
    ...serviceReducers,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(serviceMiddlewares),
});

export default store;
