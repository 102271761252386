import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  count: null,
  items: [],
  fetched: null,
  isLoading: false,
};

const itemsSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    getItems: (state, action) => ({
      ...state,
      count: action.payload.count,
      items: action.payload.results,
    }),
    changeItemStatusSlice: (state, action) => ({
        ...state,
        items: state.items.map(item => (item.id === action.payload.id ? action.payload : item)),
      }),
    clearItem: state => ({
      ...state,
      fetched: null,
    }),
  },
});

export const { getItems, changeItemStatusSlice, clearItem } = itemsSlice.actions;
export default itemsSlice.reducer;
