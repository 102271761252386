import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  saleAccountState: {},
};

const salesAccountSlice = createSlice({
  name: 'salesAccounts',
  initialState,
  reducers: {
    getSalesAccountStatementState: (state, action) => ({
      ...state,
      saleAccountState: action.payload,
    }),
  },
});

export const { getSalesAccountStatementState } = salesAccountSlice.actions;
export default salesAccountSlice.reducer;
