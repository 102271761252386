import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer } from 'react-big-calendar';
// import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';

import moment from 'moment';

const localizer = momentLocalizer(moment);

// const DragAndDropCalendar = withDragAndDrop(Calendar);

function Event({ event }) {
  return (
    <div>
      <div className="mt-1">
        <strong>{event.title}</strong>
      </div>
      <div>
        <small>{event.description}</small>
      </div>
    </div>
  );
}

Event.propTypes = {
  event: PropTypes.object.isRequired,
};

function eventStyleGetter(event) {
  let color;

  switch (event.priority) {
    case 'high':
      color = '#fa697d';
      break;

    case 'family':
      color = '#4ce1b6';
      break;

    case 'non':
      color = '#0097b2'; //  '#70bbfd';
      break;
    default:
      color = '$color-additional';
      break;
  }

  const style = {
    backgroundColor: color,
    border: 'none',
  };

  return {
    style,
  };
}

export default class CalendarComponent extends PureComponent {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     events: props.events,
  //   };
  // }

  // moveEvent = ({ event, start, end }) => {
  //   const { events } = this.state;

  //   const idx = events.indexOf(event);
  //   const updatedEvent = { ...event, start, end };

  //   const nextEvents = [...events];
  //   nextEvents.splice(idx, 1, updatedEvent);

  //   this.setState({
  //     events: nextEvents,
  //   });
  // };

  render() {
    const { small, events, dir, onSelectEvent } = this.props;
    const formats = {
      dayFormat: (date, culture) => localizer.format(date, 'DD', culture),
    };
    return (
      <div className={`calendar${small ? ' calendar--small' : ''}`}>
        <Calendar
          rtl={dir === 'rtl'}
          localizer={localizer}
          events={events}
          views={['month', 'week', 'day']}
          popup
          // getNow={() => new Date('2021-08-01')}
          formats={formats}
          step={60}
          timeslots={1}
          showMultiDayTimes
          onSelectEvent={onSelectEvent}
          // onEventDrop={this.moveEvent}
          // defaultDate={new Date()}
          eventPropGetter={eventStyleGetter}
          messages={{
            previous: <span className="lnr lnr-chevron-left" />,
            next: <span className="lnr lnr-chevron-right" />,
            today: <span className="lnr lnr-calendar-full" />,
          }}
          components={{
            event: Event,
          }}
        />
      </div>
    );
  }
}

CalendarComponent.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      allDay: PropTypes.bool,
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
      priority: PropTypes.string,
    })
  ).isRequired,
  onSelectEvent: PropTypes.func,
  small: PropTypes.bool,
  dir: PropTypes.string,
};

CalendarComponent.defaultProps = {
  small: false,
  dir: '',
  onSelectEvent: () => { },
};
