import React from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Avatar = `${process.env.PUBLIC_URL}/img/avatar.png`;

function UserCard({ user }) {
  return (
    <Col md={4} className="pl-0">
      <Link to={`/pages/hr/employees/${user.id}/profile`}>
        <Card className="user-card">
          <CardBody>
            <img
              src={
                user && user.profile && user.profile.photo
                  ? user.profile.photo
                  : Avatar
              }
              alt="user"
            />
            <h5>{user && user.profile && user.profile.employee_name}</h5>
            <p>{user && user.profile && user.profile.job_title}</p>
          </CardBody>
        </Card>
      </Link>
    </Col>
  );
}

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserCard;
