import { borderGrey } from '../themeVariables';

export const flexJustifyEnd = {
  display: 'flex',
  justifyContent: 'end',
};

export const flexAlignCenter = {
  display: 'flex',
  alignItems: 'center',
};

export const flexJustifyBetween = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const flexDirCol = {
  display: 'flex',
  flexDirection: 'column',
};

export const flexCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const flexCenterCol = {
  ...flexCenter,
  flexDirection: 'column',
};

export const borderBottom = {
  borderBottom: `1px solid ${borderGrey}`,
  paddingBottom: '1rem',
};

export const bgImageStyle = {
  backgroundSize: 'cover',
  backgroundPosition: 'center',
};

export const test = 'test';
