import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  taxRates: [],
};

const taxRatesSlice = createSlice({
  name: 'taxRates',
  initialState,
  reducers: {
    changeDefaultTaxRate: (state, action) => ({ ...state, taxRates: action.payload }),
  },
});

export const { changeDefaultTaxRate } = taxRatesSlice.actions;
export default taxRatesSlice.reducer;
