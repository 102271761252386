import { Paper } from '@mui/material';
import styled from '@emotion/styled';

const MegaMenuPaper = styled(Paper)({
    '& svg': {
        fontSize: '12px',
        marginRight: '10px',
    },
    '& h5': {
        fontSize: '14px',
        fontWeight: '500',
        marginBottom: '0.5rem',
    },
    '& h6': {
        fontSize: '12px',
        fontWeight: '400',
        marginBottom: '0.5rem',
        cursor: 'pointer',
    },
});

export default MegaMenuPaper;
