/* eslint-disable */

import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, CardContent, Card, Grid, Menu, MenuItem, Button, Typography } from '@mui/material';
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { COLORS, durationOptions } from '../../../utilis/data';
import { useGetTopExpensesQuery } from 'services/private/dashboard';
import formatAmount from 'utilities/formatAmount';
import SectionLoader from 'containers/common/loaders/SectionLoader';
import { getSorting } from 'utilities/helpers';
import { flexCenter, flexJustifyBetween } from 'styles/mui/utilis/utilis';

function TopExpenses() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const [duration, setDuration] = useState('this fiscal year');
  const { data: topExpensesData, isLoading } = useGetTopExpensesQuery(duration);

  const graphData = useMemo(() => topExpensesData
    ? [...topExpensesData.data]
      .sort(getSorting('asc', 'percentage'))
      .slice(0, 5)
      .filter(
        item => item.amount > 0 && formatAmount(item.percentage) !== '0.00'
      )
      .map(item => ({
        accountName: item.chart_of_account,
        name: `${item.chart_of_account} (${formatAmount(item.percentage)}%)`,
        value: item.amount,
      }))
    : [], [topExpensesData]);

  const handleClick = accountName => useCallback(() => {
    navigate({
      pathname: '/pages/reports/expensesByCategory/details',
      search: `?duration=custom&start_date=${topExpensesData.start_date}&end_date=${topExpensesData.end_date}&category_name=${accountName}`,
    });
  }, [topExpensesData]);

  return (
    <Grid item xl={6} md={12} lg={6} sm={12}>
      <Card sx={{ padding: '10px' }}>
        <Box sx={flexJustifyBetween}>
          <Typography variant="h4">Your Top Expenses</Typography>

          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleMenuClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {duration}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {durationOptions.map(item => (
              <MenuItem
                key={item.value}
                onClick={() => {
                  setDuration(item.value);
                  handleClose();
                }}
                active={item.value === duration}
              >
                {item.label}
              </MenuItem>
            ))}
          </Menu>

        </Box>
        <CardContent style={{ minHeight: isLoading ? 500 : '' }} >
          {isLoading ?
            <Box sx={{ ...flexCenter, height: 500 }}>
              <SectionLoader isLoading={isLoading} />
            </Box>
            : <React.Fragment>{
              graphData.length > 0 ? (
                <ResponsiveContainer width="100%" height={400}>
                  <PieChart width={400} height={400}>
                    <Pie
                      data={graphData}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {graphData.map((entry, index) => (
                        <Cell
                          key={entry.name}
                          fill={COLORS[index % COLORS.length]}
                          onClick={() => handleClick(entry.accountName)}
                          className="cursor-pointer"
                        />
                      ))}
                    </Pie>
                    <Tooltip formatter={value => `AED${formatAmount(value)}`} />
                    <Legend
                      layout="vertical"
                      verticalAlign="middle"
                      align="right"
                    />
                  </PieChart>
                </ResponsiveContainer>
              ) : (
                <Box sx={{ ...flexCenter, height: 500 }}>
                  <Typography variant='h5'>No expense recorded for {duration}</Typography>
                </Box>
              )
            }</React.Fragment>}
        </CardContent>
      </Card>
    </Grid>
  );
}

export default TopExpenses;
