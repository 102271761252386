import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { Box, Container } from '@mui/material';

import useAuthInfo from 'customHooks/useAuthInfo';
import LayoutWrapper from 'containers/common/layout';
import { mainWrapperStyles } from 'styles/mui/components/layoutStyles';

function PrivateRoutes() {
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useAuthInfo();

  if (!isAuthenticated) {
    return <Navigate to="/auth/sign-in" state={{ from: `${pathname}${search}` }} />;
  }

  return (
    <>
      <LayoutWrapper />
      <Box sx={{ ...mainWrapperStyles }}>
        <Container>
          <Outlet />
        </Container>
      </Box>
    </>
  );
}

export default PrivateRoutes;
