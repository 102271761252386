import React, { useState } from 'react';
import { List, ListItemIcon, ListItemButton, ListItemText } from '@mui/material';
import useGetSidebarLinks from 'containers/common/customHooks/useGetSidebarLinks';
import SidebarItem from './SidebarItem';
import SidebarNested from './SidebarNested';
import { ArrowBackIcon, LayersIcon } from '../../../icons/index';

function AccountantSidebar() {
  const [isSubSidebarOpen, setIsSubSidebarOpen] = useState(false);
  const { accountingSidebarLinks, subSidebarLinks } = useGetSidebarLinks();

  const toggleSubSidebar = () => setIsSubSidebarOpen(!isSubSidebarOpen);
  return (
    isSubSidebarOpen ? (
      <>
        <List>
          <ListItemButton onClick={() => toggleSubSidebar()}>
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary="Back" />
          </ListItemButton>
        </List>
        <List>
          {
            subSidebarLinks.map(link => (
              <SidebarItem
                icon={link.icon}
                title={link.title}
                path={link.path}
                key={link.path}
              />
            ))
          }
        </List>
      </>
    ) : (
      <>
        <List>
          {accountingSidebarLinks.map((link, index) => {
            if (link?.data) {
              return (
                <SidebarNested title={link.title} icon={link.icon} data={link.data} key={index} />
              );
            }
            return (
              <SidebarItem
                title={link.title}
                icon={link.icon}
                path={link.path}
                key={link.path}
              />
            );
          })}
        </List>
        <List>
          <ListItemButton onClick={() => toggleSubSidebar()}>
            <ListItemIcon>
              <LayersIcon />
            </ListItemIcon>
            <ListItemText primary="Miscellaneous" />
          </ListItemButton>
        </List>
      </>
    )
  );
}

export default AccountantSidebar;
