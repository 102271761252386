import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';
import InternalMeetingsCalendar from './components/InternalMeetingsCalendar';
import EventLabels from './components/EventLabels';
import InternalMeetingActionsForm from './components/InternalMeetingActionsForm';

function ManageInternalMeetingsPage() {
  const { companyDetail } = useSelector(state => state.auth);
  return (
    <Container>
      <Helmet>
        <title>{`Internal Meetings - ${companyDetail.company_name}`}</title>
        <meta
          name="description"
          content={`CRM - ${companyDetail.company_name}`}
        />
      </Helmet>
      <Row className="pt-3">
        <Col md={6}>
          <h3 className="page-title">Manage Internal Meetings</h3>
          <h3 className="page-subhead subhead">User internal meetings</h3>
        </Col>
        <Col md={6}>
          <InternalMeetingActionsForm />
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12} xl={9}>
          <InternalMeetingsCalendar />
        </Col>
        <EventLabels />
      </Row>
    </Container>
  );
}

export default ManageInternalMeetingsPage;
