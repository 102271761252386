import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// import MenuDownIcon from 'mdi-react/MenuDownIcon';
// import { Col, Row } from 'reactstrap';
// import { Container } from '@mui/material';
// import { Link } from 'react-router-dom';
// import useClickOutside from 'customHooks/useClickOutside';

function OverdueAmountWithPopup({ overdueAmount, popupData, type }) {
  // function OverdueAmountWithPopup() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const ref = useRef();
  // const [showPopup, setShowPopup] = useState(false);

  // const togglePopup = () => setShowPopup(prevState => !prevState);

  // useClickOutside(ref, showPopup, togglePopup);

  return (
    <div className="overdue-amount">
      <Button
        sx={{ marginTop: '5px' }}
        onClick={handleMenuClick}
        endIcon={<KeyboardArrowDownIcon />}
        variant="pageTitle"
      >
        {overdueAmount}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {popupData.map(item => (
          <MenuItem
            style={{ fontSize: '12px' }}
            onClick={() => {
              // toggle();
              navigate(`/pages/reports/${type === 'receivable' ? 'ar' : 'ap'
                }AgingDetails?duration=today&date_type=due_date&interval=${item.interval
                }`);
              handleClose();
            }}
          >
            <span style={{ marginRight: '10px' }}>{item.duration}</span> AED{item.amount}
          </MenuItem>
        ))}
      </Menu>

      {/* <button type="button" className="transparent-btn" onClick={togglePopup}>
        <span className="pay-receive-amount">
          {overdueAmount}
          <MenuDownIcon />
        </span>
      </button>
      {showPopup && (
        <div className="overdue-amount-popup">
          <div className="popup-body">
            <Container>
              {popupData.map(item => (
                <Link
                  to={`/pages/reports/${type === 'receivable' ? 'ar' : 'ap'
                    }AgingDetails?duration=today&date_type=due_date&interval=${item.interval
                    }`}
                >
                  <Row key={item.duration} className="popup-item">
                    <Col className="popup-duration">{item.duration}</Col>
                    <Col className="popup-amount">AED{item.amount}</Col>
                  </Row>
                </Link>
              ))}
            </Container>
          </div>
        </div>
      )} */}
    </div>
  );
}

OverdueAmountWithPopup.propTypes = {
  overdueAmount: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  popupData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default OverdueAmountWithPopup;
