/* eslint-disable react/react-in-jsx-scope, react/jsx-filename-extension */
import { ACCOUNTING_CONTROLLER, HR_CONTROLLER } from 'utilities/constants';

import {
  DashboardIcon,
  GroupsIcon,
  StorageIcon,
  DeleteIcon,
  LocalActivityIcon,
  AssessmentIcon,
  SettingsIcon,
  Diversity2Icon,
} from '../icons/index';

export const searchOptions = [
  { link: 'items', label: 'Items' },
  { link: 'sales/accounts', label: 'Accounts' },
  { link: 'sales/customers', label: 'Customers' },
  { link: 'sales/quotations', label: 'Quotations' },
  { link: 'sales/proInvoices', label: 'Proforma Invoices' },
  { link: 'sales/invoices', label: 'Invoices' },
  { link: 'sales/paymentsReceived', label: 'Payments Received' },
  { link: 'sales/creditNotes', label: 'Credit Notes' },
  { link: 'purchases/suppliers', label: 'Suppliers' },
  { link: 'purchases/expenses', label: 'Expenses' },
  { link: 'purchases/purOrders', label: 'Purchase Orders' },
  { link: 'purchases/bills', label: 'Bills' },
  { link: 'purchases/paymentsMade', label: 'Payments Made' },
  { link: 'purchases/supplierCredits', label: 'Debit Notes' },
  { link: 'accountant/journals', label: 'Manual Journals' },
];

export const Dashboard = {
  title: 'Dashboard',
  path: '/',
  icon: <DashboardIcon />,
};
export const Activities = {
  title: 'Activities',
  icon: <LocalActivityIcon />,
  path: '/pages/manageActivities',
};
export const salesLinks = [
  {
    title: 'Accounts',
    path: '/pages/accounting/sales/accounts',
    permissions: [],
  },
  {
    title: 'Customers',
    path: '/pages/accounting/sales/customers',
    permissions: [],
  },
  {
    title: 'Quotations',
    path: '/pages/accounting/sales/quotations',
    permissions: [],
  },
  {
    title: 'Proforma Invoices',
    path: '/pages/accounting/sales/proInvoices',
    permissions: [],
  },
  {
    title: 'Invoices',
    path: '/pages/accounting/sales/invoices',
    permissions: [],
  },
  {
    title: 'Payments Received',
    path: '/pages/accounting/sales/paymentsReceiveds',
    permissions: [],
  },
  {
    title: 'Credit Notes',
    path: '/pages/accounting/sales/creditNotes',
    permissions: [],
  },
];

export const purchasesLinks = [
  {
    title: 'Suppliers',
    path: '/pages/accounting/purchases/suppliers',
    permissions: [],
  },
  {
    title: 'Expenses',
    path: '/pages/accounting/purchases/expenses',
    permissions: [],
  },
  {
    title: 'Purchase Orders',
    path: '/pages/accounting/purchases/purOrders',
    permissions: [],
  },
  {
    title: 'Bills',
    path: '/pages/accounting/purchases/bills',
    permissions: [],
  },
  {
    title: 'Payments Made',
    path: '/pages/accounting/purchases/paymentsMade',
    permissions: [],
  },
  {
    title: 'Debit Notes',
    path: '/pages/accounting/purchases/supplierCredits',
    permissions: [],
  },
];

export const commonSidebarLinks = [
  {
    title: 'Internal Meetings',
    path: '/pages/manageInternalMeetings',
    icon: <GroupsIcon />,
    permissions: [HR_CONTROLLER, ACCOUNTING_CONTROLLER],
  },
  {
    title: 'Storage',
    path: '/pages/storage/main',
    icon: <StorageIcon />,
    permissions: [HR_CONTROLLER, ACCOUNTING_CONTROLLER],
  },
  {
    title: 'Trash',
    path: '/pages/storage/trash',
    icon: <DeleteIcon />,
    permissions: [HR_CONTROLLER, ACCOUNTING_CONTROLLER],
  },
];

export const companyLinks = [
  {
    title: 'Company Profiles',
    path: '/pages/hr/companyProfiles',
  },
  {
    title: 'Company Files',
    path: '/pages/hr/companyFiles',
  },
];

export const financeHrLinks = {
  title: 'HR',
  icon: <Diversity2Icon />,
  data: [
    {
      title: 'Salaries',
      path: '/pages/hr/salaries',
    },
    {
      title: 'Leaves',
      path: '/pages/hr/leaves',
    },
  ],
};

export const Reports = {
  title: 'Reports',
  path: '/pages/reports',
  icon: <AssessmentIcon />,
};
export const Settings = {
  title: 'Settings',
  icon: <SettingsIcon />,
  path: '/pages/settings',
};
export const InternalMeetings = {
  title: 'Internal Meetings',
  icon: <GroupsIcon />,
  path: '/pages/manageInternalMeetings',
};

export const bookingModuleLinks = [
  {
    title: 'Booking Users',
    path: '/booking/users',
    permissions: [],
  },
  {
    title: 'Search',
    path: '/booking',
    permissions: [],
  },
  {
    title: 'Booking Queues',
    path: '/booking/queues',
    permissions: [],
  },
];

export const generalListData = [
  {
    path: '/pages/accounting/items/add',
    title: 'Item',
  },
  {
    path: '/pages/accounting/accountant/journals/add',
    title: 'Journal Entry',
  },
  {
    path: '/pages/accounting/banking/add',
    title: 'Bank Account',
  },
];

export const salesListData = [
  {
    path: '/pages/accounting/sales/accounts/add',
    title: 'Account',
  },
  {
    path: '/pages/accounting/sales/customers/add',
    title: 'Customer',
  },
  {
    path: '/pages/accounting/sales/quotations/add',
    title: 'Quotation',
  },
  {
    path: '/pages/accounting/sales/proInvoices/add',
    title: 'Proforma Invoice',
  },
  {
    path: '/pages/accounting/sales/invoices/add',
    title: 'Invoice',
  },
  {
    path: '/pages/accounting/sales/paymentsReceiveds/add',
    title: 'Payment Received',
  },
  {
    path: '/pages/accounting/sales/creditNotes/add',
    title: 'Credit Note',
  },
];

export const purchasesListData = [
  {
    path: '/pages/accounting/purchases/suppliers/add',
    title: 'Supplier',
  },
  {
    path: '/pages/accounting/purchases/expenses/add',
    title: 'Expense',
  },
  {
    path: '/pages/accounting/purchases/purOrders/add',
    title: 'Purchase Order',
  },
  {
    path: '/pages/accounting/purchases/bills/add',
    title: 'Bill',
  },
  {
    path: '/pages/accounting/purchases/paymentsMade/add',
    title: 'Payment Made',
  },
  {
    path: '/pages/accounting/purchases/supplierCredits/add',
    title: 'Debit Note',
  },
];

export const notifications = [
  {
    name: 'Cristopher Changer',
    message: ' has started a new project',
    date: '09:02',
  },
  {
    name: 'Sveta Narry',
    message: ' has closed a project',
    date: '09:00',
  },
  {
    name: 'Lory McQueen',
    message: ' has started a new project as a Project Managert',
    date: '08:43',
  },
  {
    name: 'Cristopher Changer',
    message: ' has closed a project',
    date: '08:43',
  },
];
