import React from 'react';
import { IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { toggleSidebar } from 'store/slices/sidebarSlice';

import { MenuIcon } from '../../../icons/index';

function TopbarSidebarButton() {
  const dispatch = useDispatch();
  const stylings = {
    marginLeft: '0.5rem',
    marginTop: '0.3rem',
    width: '50px',
    height: '50px',
  };

  const toggleSidebarFun = () => {
    dispatch(toggleSidebar());
  };

  return (
    <IconButton sx={stylings} onClick={toggleSidebarFun}>
      <MenuIcon />
    </IconButton>
  );
}

export default TopbarSidebarButton;
