/* eslint react/forbid-prop-types: 0 */
/* eslint no-script-url: 0 */
import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router';
import { Button, Menu, MenuItem, Box, Avatar, useMediaQuery } from '@mui/material';
import Ava from 'assets/img/avatar.png';
import { useLogoutMutation } from 'services/private/auth';
import { useSnackbar } from 'notistack';
import { catchError } from 'utilities/errorMsgs';
import { onLoggedOut } from 'store/slices/authSlice';
import { useSelector, useDispatch } from 'react-redux';

function TopbarProfile() {
  const isMobile = useMediaQuery('(max-width:1300px)');
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const user = useSelector(state => state.auth.user);
  const [collapse, setCollapse] = useState(false);
  const [logout] = useLogoutMutation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggle = () => {
    setCollapse(!collapse);
  };

  const logoutFun = async () => {
    try {
      await logout();
      dispatch(onLoggedOut());
    } catch (error) {
      enqueueSnackbar(error || catchError, { variant: 'error' });
    }
  };

  return (
    <Box>
      <Button
        sx={{ marginTop: '5px' }}
        onClick={handleMenuClick}
        endIcon={<KeyboardArrowDownIcon />}
        variant="pageTitle"
      >
        {user.profile && user.profile.photo && (
          <Avatar
            src={user.profile && user.profile.photo}
            alt="avatar"
          />
        )}
        {user.profile && user.profile.photo === '' && (
          <Avatar src={Ava} alt="avatar" />
        )}
        {
          !isMobile && (
            <>
              {user.profile && user.profile.employee_name}
              {!user.profile && 'Admin User'}
            </>
          )
        }
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            toggle();
            navigate('/pages/user / profile');
            handleClose();
          }}
        >
          <Button startIcon={<PersonOutlineIcon />}>
            My Profile
          </Button>
        </MenuItem>
        <MenuItem
          onClick={() => {
            toggle();
            navigate('/pages/user/calendar');
            handleClose();
          }}
        >
          <Button startIcon={<CalendarMonthIcon />}>
            Calendar
          </Button>
        </MenuItem>
        <MenuItem
          onClick={() => {
            logoutFun();
            navigate('/auth/sign-in');
            handleClose();
          }}
        >
          <Button startIcon={<LogoutIcon />}>
            Log Out
          </Button>
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default (TopbarProfile);
