import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    users: [],
    userDocuments: [],
    usersByStatus: [],
    usersByDepartment: [],
    statuses: {
        active: 'Active',
        resigned: 'Resigned',
        terminated: 'Terminated',
    },
    fetched: null,
    recentActivity: [],
};

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        GET_RECENT_ACTIVITY: (state, action) => ({ ...state, recentActivity: action.payload }),
    },
});

export const { GET_RECENT_ACTIVITY } = usersSlice.actions;
export default usersSlice.reducer;
