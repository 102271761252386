import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  paymentTerms: [],
};

const paymentTermsSlice = createSlice({
  name: 'paymentTerms',
  initialState,
  reducers: {
    changeDefaultTerm: (state, action) => ({
      ...state,
      paymentTerms: action.payload,
    }),
  },
});

export const { getBankAccountData, getUploadFileHeaders } = paymentTermsSlice.actions;
export default paymentTermsSlice.reducer;
