import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    bankAccounts: [],
    bankStatementHeaders: [],
    bankStatements: [],
    fetched: null,
    isLoading: false,
};

const bankingSlice = createSlice({
    name: 'banking',
    initialState,
    reducers: {
        getBankAccountData: (state, action) => ({
            ...state,
            fetched: action.payload
        }),
        getUploadFileHeaders: (state, action) => ({
            ...state,
            bankStatementHeaders: action.payload
        }),
    },
});

export const { getBankAccountData, getUploadFileHeaders } = bankingSlice.actions;
export default bankingSlice.reducer;
