/* eslint-disable react/no-array-index-key */
/* eslint-disable react/self-closing-comp */
import React, { useMemo } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { Container, Card, CardContent, Box, Grid, Typography } from '@mui/material';
import round from 'lodash/round';
import { useGetTotalPayableDataQuery } from 'services/private/dashboard';
import formatAmount from 'utilities/formatAmount';
import OverdueAmountWithPopup from './OverdueAmountWithPopup';

const colors = ['#0097b2', '#a9a9a9'];

function TotalPayables() {
  const { data: totalPayables = [] } = useGetTotalPayableDataQuery();
  const currentAmount = useMemo(() => (totalPayables
    ? totalPayables.data?.current.reduce((acc, val) => {
      if (val.type === 'Bill' || val.is_credit) {
        return acc + round(val.amount_due_bcy, 2);
      }
      return acc - round(val.amount_due_bcy, 2);
    }, 0)
    : 0), [totalPayables]);

  const date1To15OverdueTotal = useMemo(() => (totalPayables
    ? totalPayables.data?.date_1_15.reduce((acc, val) => {
      if (val.type === 'Bill' || val.is_credit) {
        return acc + round(val.amount_due_bcy, 2);
      }
      return acc - round(val.amount_due_bcy, 2);
    }, 0)
    : 0), [totalPayables]);

  const date16To30OverdueTotal = useMemo(() => (totalPayables
    ? totalPayables.data?.date_16_30.reduce((acc, val) => {
      if (val.type === 'Bill' || val.is_credit) {
        return acc + round(val.amount_due_bcy, 2);
      }
      return acc - round(val.amount_due_bcy, 2);
    }, 0)
    : 0), [totalPayables]);

  const date31To45OverdueTotal = useMemo(() => (totalPayables
    ? totalPayables.data?.date_31_45.reduce((acc, val) => {
      if (val.type === 'Bill' || val.is_credit) {
        return acc + round(val.amount_due_bcy, 2);
      }
      return acc - round(val.amount_due_bcy, 2);
    }, 0)
    : 0), [totalPayables]);

  const dateGreaterThan45Total = useMemo(() => (totalPayables
    ? totalPayables.data?.date_gt_45.reduce((acc, val) => {
      if (val.type === 'Bill' || val.is_credit) {
        return acc + round(val.amount_due_bcy, 2);
      }
      return acc - round(val.amount_due_bcy, 2);
    }, 0)
    : 0), [totalPayables]);

  const overdueAmount = useMemo(() => (
    date1To15OverdueTotal +
    date16To30OverdueTotal +
    date31To45OverdueTotal +
    dateGreaterThan45Total), [date1To15OverdueTotal, date16To30OverdueTotal, date31To45OverdueTotal, dateGreaterThan45Total]);

  const totalUnpaidBills = useMemo(() => (currentAmount + overdueAmount), [currentAmount, overdueAmount]);

  const popupData = useMemo(() => ([
    {
      interval: '1_15',
      duration: '1-15 days',
      amount: formatAmount(date1To15OverdueTotal),
    },
    {
      interval: '16_30',
      duration: '16-30 days',
      amount: formatAmount(date16To30OverdueTotal),
    },
    {
      interval: '31_45',
      duration: '31-45 days',
      amount: formatAmount(date31To45OverdueTotal),
    },
    {
      interval: 'gt_45',
      duration: 'Above 45 days',
      amount: formatAmount(dateGreaterThan45Total),
    },
  ]), [date1To15OverdueTotal, date16To30OverdueTotal, date31To45OverdueTotal, dateGreaterThan45Total]);

  const dataForPieChart = useMemo(() => ([{
    name: 'Current Amount',
    value: Math.abs(currentAmount),
  },
  {
    name: 'Overdue Amount',
    value: Math.abs(overdueAmount, overdueAmount),
  },]), [currentAmount,]);

  return (
    <Grid item md={12} lg={6} xl={6}>
      <Card sx={{ padding: '10px' }}>
        <Box>
          <Typography variant="h4">Total Payables</Typography>
        </Box>
        <CardContent>
          <Container>
            <Grid container>
              <Grid item xl={6} lg={6} md={6}>
                <PieChart width={180} height={200}>
                  <Pie
                    data={dataForPieChart}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                  >
                    {
                      dataForPieChart.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                      ))
                    }
                  </Pie>
                </PieChart>
              </Grid>
              <Grid item xl={6} lg={6} md={6}>
                <Box sx={{ padding: '5px' }}>
                  <span>
                    Total overdue bills
                  </span>
                  <h2>
                    AED {formatAmount(totalUnpaidBills)}
                  </h2>
                </Box>

                <Box sx={{ padding: '5px' }}>
                  <div>
                    <span />
                    Current (AED)
                  </div>

                  {/* <p className="dashboard__eth">Current (AED)</p> */}
                  <p>
                    {formatAmount(currentAmount)}
                  </p>
                </Box>
                <Box sx={{ padding: '5px' }}>
                  <div>
                    <span />
                    Overdue (AED)
                  </div>
                  <OverdueAmountWithPopup
                    overdueAmount={formatAmount(overdueAmount)}
                    popupData={popupData}
                    type="payable"
                  />
                </Box>

              </Grid>
            </Grid>
          </Container>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default TotalPayables;
