import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  paymentModes: [],
};

const paymentModesSlice = createSlice({
  name: 'paymentModes',
  initialState,
  reducers: {
    changeDefaultMode: (state, action) => ({
      ...state,
      paymentModes: action.payload,
    }),
  },
});

export const { changeDefaultMode } = paymentModesSlice.actions;
export default paymentModesSlice.reducer;
