import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import capitalize from 'lodash/capitalize';

const renderRecord = item => (
  <tr
    className="line-item-column line-item-row table-bottom-border"
    key={item.id}
  >
    <td className="line-item-table-data text-left">
      {item.user_profile_id && item.user_profile_id.employee_name
        ? item.user_profile_id.employee_name
        : '-'}
    </td>
    <td className="line-item-table-data text-center">
      {item.leave_from_date
        ? moment(item.leave_from_date).format('DD MMM YYYY')
        : '-'}
    </td>
    <td className="line-item-table-data text-center">
      {item.leave_to_date
        ? moment(item.leave_to_date).format('DD MMM YYYY')
        : '-'}
    </td>
    <td className="line-item-table-data text-center">
      <div className={`leave-staus-badge status-${item.is_approved}`}>
        {item.is_approved ? capitalize(item.is_approved) : ''}
      </div>
    </td>
  </tr>
);

function LeavesTable({ recentLeaves }) {
  const navigate = useNavigate();

  return (
    <Card className="py-0 leaves-card">
      <CardBody className="overflow-auto">
        <table className="no-border w-100 line-item-table">
          <thead className="line-item-header">
            <tr className="border-top-bottom">
              <th className="no-border over-flow line-item-column">Employee</th>
              <th className="no-border text-center over-flow line-item-column">
                From
              </th>
              <th className="no-border text-center over-flow line-item-column">
                To
              </th>
              <th className="no-border text-center over-flow line-item-column">
                Status
              </th>
            </tr>
          </thead>
          <tbody style={{ fontSize: 13 }}>
            {recentLeaves && recentLeaves.length > 0 ? (
              recentLeaves.map(item => renderRecord(item))
            ) : (
              <tr className="line-item-column line-item-row no-border">
                <td
                  className="line-item-table-data text-center clr-add"
                  colSpan="4"
                >
                  No Recent Leaves
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {recentLeaves && recentLeaves.length > 0 && (
          <button
            type="button"
            className="btn btn-primary my-0 mt-5 w-100"
            onClick={() => navigate('/pages/hr/leaves')}
          >
            Show More
          </button>
        )}
      </CardBody>
    </Card>
  );
}

LeavesTable.propTypes = {
  recentLeaves: PropTypes.array,
};

LeavesTable.defaultProps = {
  recentLeaves: [],
};

export default LeavesTable;
