import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
// import { useSelector } from 'react-redux';

function EventLabels() {
  // const { meetings } = useSelector(state => state.internalMeetings);
  const meetings = false;
  return (
    <Col md={12} lg={12} xl={3}>
      <Card className="card--not-full-height">
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Upcoming Meetings</h5>
          </div>
          {meetings &&
            meetings
              .filter(item => new Date(item.meeting_date) > new Date())
              .sort(
                (a, b) => new Date(b.meeting_date) - new Date(a.meeting_date)
              )
              .slice(0, 5)
              .sort(
                (a, b) => new Date(a.meeting_date) - new Date(b.meeting_date)
              )
              .map(meeting => (
                <p key={meeting.id} className="typography-message">
                  <span className="calendar-label calendar-label--blue" />
                  {meeting.title}
                </p>
              ))}
        </CardBody>
      </Card>
    </Col>
  );
}

export default EventLabels;
